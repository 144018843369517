import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class ExpandableTextareaController {
  /*@ngInject*/
  constructor($element, $rootScope, $timeout) {
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;

    this.input = $element.find('textarea');
    if (angular.isUndefined(this.sendOnEnter)) {
      this.sendOnEnter = false;
    }
  }

  catchEnter(event) {
    if (!this.sendOnEnter) {
      return;
    }
    // http://stackoverflow.com/a/41656511/1617748
    // Shouldn't use .keyCode because it is deprecated, but it is still the only thing most browsers support
    const keyCode = event.keyCode;

    // Modified enter keys
    if (
      keyCode === 10 || // Ctrl + Enter in Chrome; won't work on Mac
      (keyCode === 13 && event.ctrlKey) || // Ctrl + Enter in FF
      (keyCode === 13 && event.shiftKey) || // Shift + Enter in Chrome
      (keyCode === 13 && event.altKey) // Alt + Enter in Chrome
    ) {
      event.stopPropagation();
      event.preventDefault();
      const cursorPosition = event.target.selectionStart;
      this.model =
        this.model.slice(0, cursorPosition) +
        '\n' +
        this.model.slice(cursorPosition);
      this.$timeout(() => {
        event.target.selectionStart = cursorPosition + 1;
        event.target.selectionEnd = cursorPosition + 1;
      });

      this.$rootScope.$broadcast('elastic:adjust');
      return;
    }

    // Send on enter
    if (keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      this.onSubmit();
    }
  }
}

export default angular
  .module('wc.components.inputs.expandableTextarea', [])
  .component('wcExpandableTextarea', {
    bindings: {
      class: '@wcClass',
      model: '=',
      onSubmit: '&',
      sendOnEnter: '<',
      focus: '<',
      placeholder: '@'
    },
    controller: ExpandableTextareaController,
    templateUrl: templateUrl
  });
