/*
 * Keeps properties of an internal ngModelController in sync with the parent ngModelController.
 *
 * ## Usage
 *
 * Attach the wc-sub-input directive to the internal `<input>` in a custom form component. Or any
 * internal element with an ngModel, not necessarily an `<input>` :)
 *
 * Pass a config object with two properties.
 *
 * `childProperties`: the properties you want to sync from child ngModelController to parent.
 * `parentProperties: the properties you want to sync from parent ngModelController to child.
 *
 * ## Example
 *
 * <input wc-sub-input="{childProperties: ['$touched', '$valid'], parentProperties: ['$options']}"
 */

class SubInputController {
  /*@ngInject*/
  constructor($attrs, $parse, $scope) {
    this.$scope = $scope;

    const parsedConfig = $parse($attrs.wcSubInput)();
    this.config = {
      childProperties: parsedConfig.childProperties || [],
      parentProperties: parsedConfig.parentProperties || []
    };
  }

  syncPropertyFromParentToChild(property) {
    this.$scope.$watch(
      () => this.parentNgModelController[property],
      () => {
        this.ngModelController[property] = this.parentNgModelController[
          property
        ];
      }
    );
  }

  syncPropertyFromChildToParent(property) {
    this.$scope.$watch(
      () => this.ngModelController[property],
      () => {
        this.parentNgModelController[property] = this.ngModelController[
          property
        ];
      }
    );
  }

  $onInit() {
    this.config.parentProperties.forEach(
      this.syncPropertyFromParentToChild.bind(this)
    );
    this.config.childProperties.forEach(
      this.syncPropertyFromChildToParent.bind(this)
    );
  }
}

function SubInput() {
  return {
    bindToController: true,
    controller: SubInputController,
    restrict: 'A',
    require: {
      ngModelController: 'ngModel',
      parentNgModelController: '^^ngModel'
    }
  };
}

SubInput.NAME = 'wcSubInput';

export default angular
  .module('wc.inputs.subInput', [])
  .directive(SubInput.NAME, SubInput);
