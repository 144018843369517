import React from 'react';
import PropTypes from 'prop-types';
import { ReactChips } from '../../../../../../desktop/components/react/ReactChips/ReactChips';

export const MemberProfileRegionalManagement = ({ member }) => {
  return (
    <article className="Separator">
      <h2 className="WcMemberProfileContent__Subheading">
        Regional Management
      </h2>
      <div>
        <ReactChips data={member.regionsManaged} readOnly accent />
      </div>
    </article>
  );
};

MemberProfileRegionalManagement.propTypes = {
  member: PropTypes.object
};
