class CurrencyService {
  /*@ngInject*/
  constructor(API) {
    this.isReady = API.Currencies.getList().then(this.setCurrencies.bind(this));
  }

  setCurrencies(currencies) {
    this.currencies = currencies;
    return currencies;
  }

  getCurrencySymbol(currencyCode) {
    if (currencyCode === null) {
      currencyCode = 'USD';
    }
    if (!this.currencies) {
      return '?';
    }
    const currencyObject = this.currencies.find(
      currency => currency.code === currencyCode
    );
    if (!currencyObject) {
      throw new Error(`Could not find currency for ${currencyCode}`);
    }
    return currencyObject ? currencyObject.symbol : currencyObject.code;
  }

  currencyListToMap(currencyList) {
    const map = {};
    currencyList.forEach(currency => {
      map[currency.code] = currency;
    });

    return map;
  }

  formatCurrency(currency) {
    if (!currency) {
      return;
    }

    return `${currency.code} ${currency.symbol}`;
  }
}

export default angular
  .module('wcCommon.services.currency', [])
  .service('CurrencyService', CurrencyService);
