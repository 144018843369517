import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'now',
    ss: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
});

function getOneOrMoreAgo(amount, unit) {
  return amount + ' ' + unit + (amount === 1 ? '' : 's') + ' ago';
}

export default function dateFilter() {
  return function filterDate(input) {
    let years = moment().diff(input, 'years');
    let months = moment().diff(input, 'months');
    let weeks = moment().diff(input, 'weeks');
    let days = moment().diff(input, 'days');
    let hours = moment().diff(input, 'hours');
    let minutes = moment().diff(input, 'minutes');

    if (years !== 0) {
      return getOneOrMoreAgo(years, 'year');
    }

    if (months !== 0) {
      return getOneOrMoreAgo(months, 'month');
    }

    if (weeks !== 0) {
      return getOneOrMoreAgo(weeks, 'week');
    }

    if (days !== 0) {
      return getOneOrMoreAgo(days, 'day');
    }

    if (hours !== 0) {
      return getOneOrMoreAgo(hours, 'hour');
    }

    if (minutes !== 0) {
      return getOneOrMoreAgo(minutes, 'minute');
    }

    return 'now';
  };
}

dateFilter.NAME = 'wcShortDate';
