import { ActionTypes } from './actions';
import createReducer from '../reducer';

const initialState = {
  newEmail: '',
  newMobileNumber: ''
};

const clearState = state => {
  return Object.assign({}, state, initialState);
};

const updateEmail = (state, action) => {
  return Object.assign({}, state, {
    ...state,
    newEmail: action.email
  });
};

const updateMobileNumber = (state, action) => {
  return Object.assign({}, state, {
    ...state,
    newMobileNumber: action.mobileNumber
  });
};

const handlers = {
  [ActionTypes.CLEAR_STORE]: clearState,
  [ActionTypes.UPDATE_EMAIL]: updateEmail,
  [ActionTypes.UPDATE_MOBILE_NUMBER]: updateMobileNumber
};

const changeContactReducer = (state = initialState, action) => {
  return createReducer(state, action, handlers);
};

export default changeContactReducer;
export { initialState };
