import TooltipIconTemplateUrl from './TooltipIcon.html';

let tooltipIcon = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: TooltipIconTemplateUrl,
    scope: {
      message: '@'
    }
  };
};

tooltipIcon.NAME = 'tooltipIcon';

export default tooltipIcon;
