import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param  {boolean} accent - Uses to modify the Styling
 * @param  {array} data - The supplied `pill` data
 * @param  {boolean=} readOnly - The `data` property cannot be updated
 * @param  {string=} title - The Text to display
 * @param  {string=} isSelectedKey - NOT SURE
 * @param  {string=} titleKey - NOT SURE
 * @param  {function=} onClickFn - The passed fn to update the value
 */
export const ReactChips = ({
  accent,
  data,
  onClickFn,
  readOnly,
  title,
  isSelectedKey = 'isSelected',
  titleKey = 'name'
}) => {
  // console.log(`ReactChips! | accent: ${accent} ${typeof accent}
  // | readOnly: ${readOnly} | title: ${title} `);
  // console.log(`data is: `, data)
  // console.log(`titleKey: ${titleKey}`)

  // `internal` properties generated TODO resolve passing of the FN
  /* eslint-disable no-unused-vars */
  const onClick = readOnly ? null : onClickFn;
  /* eslint-enable no-unused-vars */

  return (
    <div className="Chips__Container">
      {title && (
        <h3 className={`Chips__Title ${accent ? 'Chips__Title--accent' : ''}`}>
          {title}
        </h3>
      )}
      <div className="Chips__Container__Chips">
        {data &&
          data.map((chip, idx) => (
            <span
              className={`Chip
             ${!readOnly ? 'Chip--clickable' : ''}
             ${accent ? 'Chip--accent' : ''} 
             ${!chip[titleKey] ? 'Chip--unknown' : ''} 
             ${chip[isSelectedKey] ? 'Chip--selected' : ''}
             `}
              key={idx}
              title={`${chip[titleKey] ? chip[titleKey] : 'Unknown'}`}
              aria-label={`${chip[titleKey] ? chip[titleKey] : 'Unknown'}`}
            >
              {chip[titleKey] ? chip[titleKey] : '?'}
            </span>
          ))}
      </div>
    </div>
  );
};

ReactChips.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  onClickFn: PropTypes.func, // optional need to work this out in due course
  titleKey: PropTypes.string,
  accent: PropTypes.any,
  readOnly: PropTypes.any,
  isSelectedKey: PropTypes.string
};
