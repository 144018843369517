import missingProfilePictureSvg from '../../../assets/img/better-missing-profile-image.svg';
import missingGroupPictureSvg from '../../../assets/img/missing-group-image-placeholder.svg';

export const MISSING_IMAGE_URL = 'https://placehold.it/150/f7f7f7/?text=?';
export const MISSING_PROFILE_PICTURE_URL =
  'https://wintercircle.s3.amazonaws.com/missing-profile-picture.png';
export const MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64 =
  'data:image/svg+xml;base64,' + btoa(missingProfilePictureSvg);
export const MISSING_GROUP_PICTURE_BASE64 =
  'data:image/svg+xml;base64,' + btoa(missingGroupPictureSvg);
export const MISSING_COMPANY_LOGO =
  'https://placehold.it/300x150/f7f7f7/?text=?';
