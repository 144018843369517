import angular from 'angular';

import currency from './numerical/currency';
import fileUploads from './fileUploads';
import numberFormat from './numerical/format';
import messages from './messages';
import month from './numerical/month/MonthInput.directive';
import password from './password';
import subInput from './subinput';

export default angular.module('wcCommon.components.inputs', [
  currency.name,
  fileUploads.name,
  messages.name,
  month.name,
  numberFormat.name,
  password.name,
  subInput.name
]);
