import angular from 'angular';

import cappedNumber from './CappedNumber.filter';
import companySummary from './CompanySummary.filter';
import internationalPhoneNumber from './InternationalPhoneNumber.filter';
import largeNumbersFilter from './LargeNumbersFilter.filter';
import locationSummary from './LocationSummary.filter';
import shortDate from './ShortDate.filter';
import useFilter from './UseFilter.filter';

let dependencies = [companySummary.name, locationSummary.name];

let FilterModule = angular
  .module('wcCommon.filters', dependencies)
  .filter(cappedNumber.NAME, cappedNumber)
  .filter(useFilter.NAME, useFilter)
  .filter(largeNumbersFilter.NAME, largeNumbersFilter)
  .filter(internationalPhoneNumber.NAME, internationalPhoneNumber)
  .filter(shortDate.NAME, shortDate);

export default FilterModule;
