import angular from 'angular';
import { react2angular } from 'react2angular';
import { PositionOverview } from './PositionOverview';

export default angular
  .module('wc.components.PositionOverview', [])
  // @ts-ignore
  .component(
    'reactPositionOverview',
    react2angular(
      PositionOverview,
      ['position', 'profile', 'remuneration', 'showEmpty', 'last'],
      ['CurrencyService', 'CompensationsBlockedService']
    )
  );
