/*@ngInject*/
function WcFullResponseRestangular(WcApiRestangular) {
  return WcApiRestangular.withConfig(RestangularConfigurer => {
    RestangularConfigurer.setFullResponse(true);
  });
}

export default angular
  .module('wcCommon.services.api.fullResponseRestangular', [])
  .factory('WcFullResponseRestangular', WcFullResponseRestangular);
