import { Loader } from 'google-maps';

class GoogleMapService {
  constructor($q, GoogleMapsConfig, EnvConfig) {
    const loader = new Loader(EnvConfig.googleMapsKey, GoogleMapsConfig);

    this.gmaps = $q(resolve => {
      loader.load().then(gmaps => resolve(gmaps));
    });
  }

  /* Retrieve a reference to google maps library
   *
   * Returns a promise.
   */
  get() {
    return this.gmaps;
  }

  /* Build a map at a given element with given config
   *
   * Returns a reference to the map.
   */
  buildMap(element, config) {
    return this.get().then(gmaps => new gmaps.maps.Map(element, config));
  }
}

GoogleMapService.$inject = ['$q', 'GoogleMapsConfig', 'EnvConfig'];
GoogleMapService.NAME = 'GoogleMapService';

export default GoogleMapService;
