/* eslint-disable no-undef */
if (typeof Raven !== 'undefined' && process.env.NODE_ENV !== 'development') {
  Raven.config('https://ee32b5bedbd14dd5b8ff6ed5603faf27@sentry.io/225637', {
    release: version,
    environment: process.env.RAVEN_ENV
      ? process.env.RAVEN_ENV
      : process.env.NODE_ENV === 'development'
      ? 'local'
      : RAVEN_ENV
  }).install();
}
/* eslint-enable no-undef */
