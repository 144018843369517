import uuidv4 from 'uuid/v4';
const Chat = require('twilio-chat');

class ChatService {
  /*@ngInject*/
  constructor($q, featureFlags, API, Restangular) {
    this.$q = $q;
    this.API = API;
    this.Restangular = Restangular;
    this._readyDeferred = this.$q.defer();
    this.readyPromise = this._readyDeferred.promise;

    if (!featureFlags.isOn('messaging')) {
      return;
    }
  }

  init(userId) {
    if (!userId) {
      return;
    }

    if (userId) {
      this.userId = userId.toString();
    }

    this.refreshToken();
  }

  refreshToken() {
    const identityTokenPromise = this._getIdentityToken().then(
      this._unwrapIdentityTokenResponse.bind(this)
    );

    identityTokenPromise.then(this._initClient.bind(this));
  }

  _initClient(rsp) {
    if (!rsp || !this.userId || !this.identityToken) {
      return;
    }

    if (this.client) {
      this.client.updateToken(this.identityToken);
    } else {
      this._setClient(this.identityToken);
    }
  }

  getClient() {
    return this.client;
  }

  async lastMessageHasBeenRead(conversation) {
    if (!conversation.lastMessage) {
      return true;
    }
    return (await conversation.getUnconsumedMessagesCount()) === 0;
  }

  logout(callback) {
    this._readyDeferred = this.$q.defer();
    this.readyPromise = this._readyDeferred.promise;
    if (this.client) {
      this.client.shutdown().then(() => {
        this.client = null;
        if (callback) {
          callback();
        }
      });
    } else if (callback) {
      callback();
    }
  }

  onReady(callback) {
    this.readyPromise.then(callback);
  }

  getDate(d) {
    if (d instanceof Date) {
      return d;
    }
    return Date.parse(d);
  }

  async getLatestDate(conversation) {
    const lastMessageDate = await this.getLastMessageDate(conversation);
    return this.getDate(lastMessageDate);
  }

  async sortConversations(conversations) {
    const objMap = await Promise.all(
      conversations.map(async conversation => {
        const date = await this.getLatestDate(conversation);
        return {
          id: conversation.sid,
          conversation: conversation,
          date: date
        };
      })
    );

    const conversationsWithDate = objMap.reduce(function(map, obj) {
      map[obj.id] = obj;
      return map;
    }, {});

    conversations.sort((a, b) => {
      return (
        conversationsWithDate[b.sid].date - conversationsWithDate[a.sid].date
      );
    });
    return conversations;
  }

  async refreshConversations(callback) {
    return this.getConversations().then(async rsp => {
      await this.sortConversations(rsp.conversations);
      callback(rsp);
      return rsp;
    });
  }

  async getConversations() {
    const channels = await this.client.getUserChannelDescriptors();
    const conversations = await Promise.all(
      channels.items.map(async channel => await channel.getChannel())
    );
    const totalUnread = await this.getUnreadCount(conversations);
    return {
      conversations: conversations,
      unread: totalUnread
    };
  }

  async getConversationUnread(channel) {
    let count = await channel.getUnconsumedMessagesCount();
    if (count === null) {
      count = await channel.getMessagesCount();
    }
    return count;
  }

  async getUnreadCount(conversations) {
    const allUnreadCounts = await Promise.all(
      conversations.map(async channel => {
        return await this.getConversationUnread(channel);
      })
    );
    return allUnreadCounts.reduce((acc, current) => acc + current, 0);
  }

  async getLastMessage(conversation) {
    let paginator = await conversation.getMessages(1);
    if (paginator.items.length) {
      return paginator.items[0];
    }
    return null;
  }

  async getLastMessageBody(conversation) {
    if (!conversation.lastMessage) {
      return null;
    }
    const lastMessage = await this.getLastMessage(conversation);
    return lastMessage ? lastMessage.body : null;
  }

  async getLastMessageDate(conversation) {
    if (conversation.lastMessage) {
      const lastMessage = await this.getLastMessage(conversation);
      if (lastMessage) {
        return lastMessage.timestamp;
      }
    }

    if (!conversation.dateUpdated) {
      return conversation.dateCreated;
    }
    return conversation.dateUpdated;
  }

  _createChannel(participants, conversationId) {
    return this.client
      .createChannel({
        isPrivate: true,
        uniqueName: conversationId
      })
      .then(function(channel) {
        participants.forEach(userIdentity => {
          channel.add(userIdentity);
        });
        return channel;
      });
  }

  _getChannel(participants, conversationId) {
    if (!conversationId) {
      conversationId = uuidv4();
    }
    return this.client
      .getChannelByUniqueName(conversationId)
      .catch(() => this._createChannel(participants, conversationId));
  }

  makeConversationId(participantIds) {
    participantIds.sort();
    return participantIds.join(':');
  }

  startConversation(otherUserId) {
    let deferred = this.$q.defer();

    const augmentResponseWithId = conversation => {
      deferred.resolve({
        conversation: conversation,
        userId: otherUserId.toString()
      });
    };

    const createConversation = participants => {
      const conversationId = this.makeConversationId(participants);

      return this._getChannel(participants, conversationId);
    };

    const participants = [this.userId, otherUserId.toString()].sort();

    createConversation(participants).then(channel => {
      augmentResponseWithId(channel);
    });
    return deferred.promise;
  }

  sendSimpleMessage(conversation, messageText) {
    if (!messageText || !messageText.length) {
      return;
    }

    return conversation.sendMessage(messageText).then(() => {
      conversation.setAllMessagesConsumed();
    });
  }

  _unwrapIdentityTokenResponse(rsp) {
    this.identityToken = rsp.chatToken;
    return rsp;
  }

  _getIdentityToken() {
    return this.API.retrieveIdentityToken();
  }

  eventListener(client, state) {
    if (state === 'connected') {
      this.client = client;
      this._readyDeferred.resolve(client);
    }
  }

  _setClient(accessToken) {
    Chat.Client.create(accessToken).then(client => {
      client.on(
        'connectionStateChanged',
        this.eventListener.bind(this, client)
      );
    });
  }
}

ChatService.NAME = 'ChatService';

export default ChatService;
