/* eslint angular/window-service:0 */
/* eslint angular/document-service:0 */
/* eslint angular/definedundefined:0 */

export default function clamp(element, options) {
  const nativeIsSupported = isNativeClampSupported(element);
  if (!nativeIsSupported) {
    return;
  }
  const targetLines = options.clamp ? parseInt(options.clamp, 10) : 2;
  if (targetLines < 2) {
    throw 'Not clamping to less than 2 lines. Use CSS instead.';
  }
  const targetMaxHeight = getLineHeight(element) * targetLines;
  setCssText(element, { height: 'auto' });
  if (parseInt(getComputedStyle(element, 'height'), 10) > targetMaxHeight) {
    const style = {
      display: '-webkit-box',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': targetLines,
      height: targetMaxHeight + 'px'
    };
    setCssText(element, style);
  }
}

function setCssText(element, style) {
  element.style.cssText +=
    '; ' +
    Object.keys(style)
      .map(property => `${property}: ${style[property]}`)
      .join('; ');
}

function isNativeClampSupported(element) {
  return typeof element.style.webkitLineClamp !== 'undefined';
}

function getLineHeight(element) {
  let lineHeight = getComputedStyle(element, 'line-height');
  if (lineHeight === 'normal') {
    // Depends on the user agent. Desktop browsers (including Firefox) use a default
    // value of roughly 1.2, depending on the element's font-family.
    lineHeight = parseInt(getComputedStyle(element, 'font-size')) * 1.2;
  }
  return parseFloat(lineHeight);
}

function getComputedStyle(element, property) {
  return window.getComputedStyle(element, null).getPropertyValue(property);
}
