import angular from 'angular';
import { react2angular } from 'react2angular';
import { ImageBubbleList } from './ImageBubbleList';

export default angular
  .module('wc.components.ImageBubbleList', [])
  // @ts-ignore
  .component(
    'reactImageBubbleList',
    react2angular(ImageBubbleList, ['data', 'sourceField', 'captionField'])
  );
