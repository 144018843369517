import React from 'react';
import PropTypes from 'prop-types';
import { NonExecPositionOverview } from '../../../position/non-exec-position-overview/NonExecPositionOverview';

export const MemberProfileNonExecPositions = ({ member }) => {
  return (
    <article className="WcMemberProfileContent__WorkHistory Separator">
      <h2 className="WcMemberProfileContent__Subheading">
        Non-Executive Positions
      </h2>
      <div>
        {member.nonExecPositions.map(position => (
          <span
            key={position.id}
            className="WcMemberProfileContent__PositionOverview"
          >
            <NonExecPositionOverview position={position} />
          </span>
        ))}
      </div>
    </article>
  );
};

MemberProfileNonExecPositions.propTypes = {
  member: PropTypes.object
};
