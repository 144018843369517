import angular from 'angular';

// TODO: styles are split between here and src/styles/components/Chips.
// Merge.
// See #1953
import './Chips.scss';
import './styles.scss';
import ChipsTemplateUrl from './template.html';

let ChipsDirective = () => {
  return {
    restrict: 'E',
    templateUrl: ChipsTemplateUrl,
    scope: {
      title: '@',
      data: '=',
      titleKey: '@?',
      isSelectedKey: '@?',
      onClickFn: '&?',
      accent: '=?'
    },
    link: (scope, elem, attrs) => {
      scope.readOnly = angular.isDefined(attrs.readOnly);
      scope.titleKey = scope.titleKey ? scope.titleKey : 'name';
      scope.isSelectedKey = scope.isSelectedKey
        ? scope.isSelectedKey
        : 'isSelected';
      scope.onClick = scope.readOnly ? null : scope.onClickFn;
    }
  };
};

export default angular
  .module('wcCommon.components.chips', [])
  .directive('chips', ChipsDirective);
