import angular from 'angular';
import { react2angular } from 'react2angular';
import { RemunerationOverview } from './RemunerationOverview';

export default angular
  .module('wc.components.RemunerationOverview', [])
  // @ts-ignore
  .component(
    'reactRemunerationOverview',
    react2angular(
      RemunerationOverview,
      ['remuneration'],
      ['CurrencyService', 'CompensationsBlockedService']
    )
  );
