import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    // shades of gray
    primary: {
      '50': '#666679',
      '100': '#5b5b6b',
      '200': '#4f4f5d',
      '300': '#434350',
      '400': '#383842',
      '500': '#2C2C34',
      '600': '#202026',
      '700': '#151518',
      '800': '#09090b',
      '900': '#000000',
      A100: '#727287',
      A200: '#7f7f93',
      A400: '#8d8d9f',
      A700: '#000000',
      contrastDefaultColor: 'light'
    },
    // shades of brown
    secondary: {
      '50': '#d8d2c8',
      '100': '#cec5b9',
      '200': '#c3b9aa',
      '300': '#b9ad9b',
      '400': '#aea08c',
      '500': '#A4947D',
      '600': '#99886e',
      '700': '#8c7b62',
      '800': '#7d6e57',
      '900': '#6e604d',
      A100: '#e3ded7',
      A200: '#A4947D',
      A400: '#A4947D',
      // TODO(mihail): figure out how to avoid this
      //A400: '#f8f7f5',
      A700: '#5f5342',
      contrastDefaultColor: 'light'
    },
    // shades of red
    error: {
      '50': '#d5a0a0',
      '100': '#cd8e8e',
      '200': '#c57c7c',
      '300': '#bd6a6a',
      '400': '#b65959',
      '500': '#D27979',
      '600': '#984343',
      '700': '#873b3b',
      '800': '#753434',
      '900': '#632c2c',
      A100: '#ddb1b1',
      A200: '#e5c3c3',
      A400: '#ecd5d5',
      A700: '#D27979'
    },
    extraColors: {
      switch: '#11a595',
      switchTransparent: 'rgba(25, 236, 214, 0.08)',
      lightGrey: 'rgba(158, 158, 158, 0.2)',
      lighterGrey: 'rgba(164, 148, 125, 0.08)',
      inputBorder: 'rgb(224, 224, 224)',
      textWeak: '#8c8c8c',
      textWeaker: '#d3d3d3'
    }
  }
});

export default theme;
