import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileCareerHistory } from './MemberProfileCareerHistory';

export default angular
  .module('wc.components.MemberProfileCareerHistory', [])
  // @ts-ignore
  .component(
    'reactMemberProfileCareerHistory',
    react2angular(
      MemberProfileCareerHistory,
      ['member', 'isClient'],
      ['CurrencyService', 'CompensationsBlockedService']
    )
  );
