import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ImageBubble } from '../../image-bubble/ImageBubble';

export const ImageBubbleList = ({ data, sourceField, captionField }) => {
  return (
    <React.Fragment>
      <div className="ImageBubbleList">
        {data.map(item => (
          <ImageBubble
            key={item.id}
            src={item[sourceField]}
            caption={item[captionField]}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

ImageBubbleList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sourceField: PropTypes.string,
  captionField: PropTypes.string
};
