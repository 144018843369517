import angular from 'angular';

import GoogleMapService from './GoogleMap.service';
import PlaceSearchService from './PlaceSearch.service';

let PlacesModule = angular
  .module('wc.components.places', [])
  .service(GoogleMapService.NAME, GoogleMapService)
  .service(PlaceSearchService.NAME, PlaceSearchService);

export default PlacesModule;
