import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileExperienceLimited } from './MemberProfileExperienceLimited';

export default angular
  .module('wc.components.MemberProfileExperienceLimited', [])
  // @ts-ignore
  .component(
    'reactMemberProfileExperienceLimited',
    react2angular(
      MemberProfileExperienceLimited,
      ['member'],
      ['CurrencyService', 'CompensationsBlockedService']
    )
  );
