import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileNonExecPositions } from './MemberProfileNonExecPositions';

export default angular
  .module('wc.components.MemberProfileNonExecPositions', [])
  // @ts-ignore
  .component(
    'reactMemberProfileNonExecPositions',
    react2angular(MemberProfileNonExecPositions, ['member'])
  );
