import angular from 'angular';

import config from '../config';
import tokenService from '../Token';
import apiService from './API.factory';
import customRestangulars from './CustomRestangulars';
import fullResponseRestangular from './FullResponseRestangular';
import DocumentDownloadMethod from './DocumentDowloadMethod.config';
import RequestResponseInterception from './RequestResponseInterception.run';
import ResumeFactory from './Resume.factory';
import resumeRestangular from './ResumeRestangular';
import wcApiRestangular from './WcApiRestangular.factory';

let DomainModule = angular
  .module('wcCommon.services.api', [
    'restangular',
    'ui.router',
    apiService.name,
    config.name,
    customRestangulars.name,
    tokenService.name,
    fullResponseRestangular.name,
    resumeRestangular.name,
    wcApiRestangular.name
  ])
  .config(DocumentDownloadMethod)
  .run(RequestResponseInterception)
  .factory(ResumeFactory.NAME, ResumeFactory);

export default DomainModule;
