const MAX_PROFILE_PICTURE_SIZE_BYTES = 2000000; // 2MB
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png'];

export function validate(file) {
  if (ALLOWED_IMAGE_TYPES.indexOf(file.type) === -1) {
    return {
      isValid: false,
      error: `The file you selected is not a valid JPG or PNG image.`
    };
  }

  if (file.size > MAX_PROFILE_PICTURE_SIZE_BYTES) {
    return {
      isValid: false,
      error: `The file you selected exceeds the maximum upload size of 2MB.`
    };
  }

  return { isValid: true };
}
