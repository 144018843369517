import angular from 'angular';
import 'ng-file-upload';

import fileUploader from './file';
import imageUploader from './image';
import documentUploader from './document';

export default angular.module('wcCommon.components.inputs.fileUploads', [
  'ngFileUpload',
  fileUploader.name,
  imageUploader.name,
  documentUploader.name
]);
