import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const CompanyLogo = ({ src, alt }) => {
  return src ? (
    <div className="CompanyLogoContainer">
      <img alt={alt} src={src} />
    </div>
  ) : (
    <React.Fragment />
  );
};

CompanyLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};
