import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class ErrorViewController {
  /*@ngInject*/
  constructor($window) {
    this.$window = $window;
  }

  reloadApp() {
    // Force a reload of the app rather than using $state.go or $location. This gives the app a
    // chance to rid itself of any bad state that landed us here.
    this.$window.location.href = '/';
  }
}

export default angular
  .module('wcCommon.components.errorViews.errorView', [])
  .component('wcErrorView', {
    bindings: {
      heading: '@',
      info: '@'
    },
    controller: ErrorViewController,
    templateUrl: templateUrl
  });
