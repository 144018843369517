import { ActionTypes as CommonActionTypes } from '../actions';

const ActionTypes = {
  ...CommonActionTypes,
  SET_SESSION: 'SET_SESSION',
  CLEAR_SESSION: 'CLEAR_SESSION'
};

const Actions = {
  setSession(dispatch, sessionId) {
    dispatch({ type: ActionTypes.SET_SESSION, sessionId });
  },
  clearSession(dispatch) {
    dispatch({ type: ActionTypes.CLEAR_SESSION });
  }
};

export { Actions, ActionTypes };
