import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileCandidateSummary } from './MemberProfileCandidateSummary';

export default angular
  .module('wc.components.MemberProfileCandidateSummary', [])
  // @ts-ignore
  .component(
    'reactMemberProfileCandidateSummary',
    react2angular(MemberProfileCandidateSummary, ['summary'])
  );
