import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileRegionalManagement } from './MemberProfileRegionalManagement';

export default angular
  .module('wc.components.MemberProfileRegionalManagement', [])
  // @ts-ignore
  .component(
    'reactMemberProfileRegionalManagement',
    react2angular(MemberProfileRegionalManagement, ['member'])
  );
