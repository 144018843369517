import angular from 'angular';

import ConfigProvider from './provider';

// angular-feature-flags expects 'angular' to be available on global scope
// Use Webpack's imports-loader to pass the local `angular` variable
// See: https://webpack.github.io/docs/shimming-modules.html
require('imports-loader?angular=angular!angular-feature-flags');

export default angular
  .module('wcCommon.config', ['feature-flags'])
  .provider(ConfigProvider.NAME, ConfigProvider);
