import angular from 'angular';
import { react2angular } from 'react2angular';
import { EducationOverview } from './EducationOverview';

export default angular
  .module('wc.components.EducationOverview', [])
  // @ts-ignore
  .component(
    'reactEducationOverview',
    react2angular(EducationOverview, ['educations'])
  );
