import angular from 'angular';

import templateUrl from './template.html';
import logo from './logo-mh.svg';
import './styles.scss';

export default angular.module('wc.components.logomh', []).component('logomh', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    inverse: '<'
  },
  /*@ngInject*/
  controller: function($sce) {
    this.logoSvg = $sce.trustAsHtml(logo);
  }
});
