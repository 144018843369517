import angular from 'angular';
import { react2angular } from 'react2angular';
import { NonExecPositionOverview } from './NonExecPositionOverview';

export default angular
  .module('wc.components.NonExecPositionOverview', [])
  // @ts-ignore
  .component(
    'reactNonExecPositionOverview',
    react2angular(NonExecPositionOverview, ['position', 'isOwn'])
  );
