import { ActionTypes as CommonActionTypes } from '../actions';

const ActionTypes = {
  ...CommonActionTypes,
  UPDATE_MY_PROFILE: 'UPDATE_MY_PROFILE',
  UPDATE_EDUCATIONS: 'UPDATE_EDUCATIONS',
  UPDATE_COUNTRY_OPTIONS: 'UPDATE_COUNTRY_OPTIONS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS'
};

const Actions = {
  updateMyProfile(dispatch, myProfile) {
    dispatch({
      type: ActionTypes.UPDATE_MY_PROFILE,
      myProfile
    });
  },
  // TODO(mihail): maybe add updateFilteredProfileField
  updateEducations(dispatch, educations) {
    dispatch({
      type: ActionTypes.UPDATE_EDUCATIONS,
      educations: educations
    });
  },
  updateCountryOptions(dispatch, countryOptions) {
    dispatch({
      type: ActionTypes.UPDATE_COUNTRY_OPTIONS,
      countryOptions: countryOptions
    });
  },
  updateAddress(dispatch, address) {
    dispatch({
      type: ActionTypes.UPDATE_ADDRESS,
      address: address
    });
  }
};

export { Actions, ActionTypes };
