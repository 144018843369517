import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CircularImage } from '../../circular-image/CircularImage';
import { MISSING_IMAGE_URL } from '../../../../services/ImageUrls';

export const ImageBubble = ({ src, caption }) => {
  return (
    <div className="ImageBubble">
      <CircularImage
        className="ImageBubble__Image"
        src={src}
        missingImageUrl={MISSING_IMAGE_URL}
      />
      <p className="ImageBubble__Caption">{caption}</p>
    </div>
  );
};

ImageBubble.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string
};
