import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CompensationHidden } from '../../compensation-hidden/CompensationHidden';
import { filterLargeNumbers } from '../../../../services/LargeNumbers';
import { RemunerationGraph } from '../../remuneration-graph/RemunerationGraph';
import { ReactChips } from './../../../../../desktop/components/react/ReactChips/ReactChips';

export const RemunerationOverview = ({
  remuneration,
  CurrencyService,
  CompensationsBlockedService
}) => {
  const [isExpandable] = useState(!remuneration.company);
  const [remunerationExpanded, setRemunerationExpanded] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    setSelectedCurrency(
      CurrencyService.getCurrencySymbol(remuneration.totalCompensationCurrency)
    );
    if (remuneration.other && remuneration.other.length) {
      setBenefits(
        remuneration.other.map(benefit => {
          return { name: benefit };
        })
      );
    }
  }, [CurrencyService]);

  const expandRemuneration = () => {
    if (isExpandable) {
      setRemunerationExpanded(state => !state);
    }
  };
  return (
    <React.Fragment>
      {!remuneration && (
        <div className="RemunerationOverview RemunerationOverview--NotExpandable">
          <div className="layout-row">
            <div className="RemunerationOverview__Field RemunerationOverview__Field--Total">
              <div className="RemunerationOverview__Label">
                Total Compensation
              </div>
              <div className="RemunerationOverview__CompensationsBlocked">
                <CompensationHidden
                  CompensationsBlockedService={CompensationsBlockedService}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {remuneration && remuneration.totalCompensationAmount && (
        <div
          className={`RemunerationOverview ${
            !isExpandable ? 'RemunerationOverview--NotExpandable' : ''
          }`}
          onClick={expandRemuneration}
        >
          <div className="layout-row">
            <div
              className={`RemunerationOverview__Field RemunerationOverview__Field--Total ${
                remunerationExpanded
                  ? 'RemunerationOverview__Field--Total--expanded'
                  : ''
              }`}
            >
              <div className="RemunerationOverview__Label">
                Total Compensation
              </div>
              <div className="RemunerationOverview__Amount">
                {filterLargeNumbers(
                  remuneration.totalCompensationAmount,
                  0,
                  selectedCurrency
                )}
              </div>
              <div className="Form__Graph flex">
                <RemunerationGraph
                  remuneration={remuneration}
                  rowLayout={true}
                />
              </div>

              {isExpandable && (
                <div
                  className="md-icon-button RemunerationOverview__ExpandButton"
                  aria-label="Read more about this remuneration"
                >
                  <span
                    className={`zmdi ${
                      remunerationExpanded
                        ? 'zmdi-chevron-up'
                        : 'zmdi-chevron-down'
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
          {isExpandable && remunerationExpanded && (
            <div>
              <div className="layout-row">
                <div className="RemunerationOverview__Field">
                  <div className="RemunerationOverview__Label">
                    Basic Salary
                  </div>
                  <div className="RemunerationOverview__Amount RemunerationField__Salary">
                    {filterLargeNumbers(
                      remuneration.salaryAmount,
                      0,
                      selectedCurrency
                    )}
                  </div>
                </div>
              </div>

              <div className="layout-row">
                <div className="RemunerationOverview__Field">
                  <div className="RemunerationOverview__Label">Cash Bonus</div>
                  <div className="RemunerationOverview__Amount RemunerationField__Cash">
                    {filterLargeNumbers(
                      remuneration.bonusAmount,
                      0,
                      selectedCurrency
                    )}
                  </div>
                </div>

                <div className="RemunerationOverview__Field">
                  <div className="RemunerationOverview__Label">
                    Long-Term Cash
                  </div>
                  <div className="RemunerationOverview__Amount RemunerationField__LongTermCash">
                    {filterLargeNumbers(
                      remuneration.longTermCashAmount,
                      0,
                      selectedCurrency
                    )}
                  </div>
                </div>
              </div>

              <div className="layout-row">
                <div className="RemunerationOverview__Field">
                  <div className="RemunerationOverview__Label">
                    Stock / Options Award
                  </div>
                  <div className="RemunerationOverview__Amount RemunerationField__StockOptions">
                    {filterLargeNumbers(
                      remuneration.stockAndOptionsAmount,
                      0,
                      selectedCurrency
                    )}
                  </div>
                </div>

                {remuneration.otherFinancialAmount && (
                  <div className="RemunerationOverview__Field">
                    <div className="RemunerationOverview__Label">Other</div>
                    <div className="RemunerationOverview__Amount RemunerationField__Other">
                      {filterLargeNumbers(
                        remuneration.otherFinancialAmount,
                        0,
                        selectedCurrency
                      )}
                    </div>
                  </div>
                )}
              </div>

              {remuneration.buyoutAmount && (
                <React.Fragment>
                  {remuneration.buyoutAmount && (
                    <div className="layout-row">
                      <div className="RemunerationOverview__Field RemunerationOverview__Field--Buyout">
                        <div className="RemunerationOverview__Label">
                          Total Buyout
                        </div>
                        <div className="RemunerationOverview__Amount">
                          {filterLargeNumbers(
                            remuneration.buyoutAmount,
                            0,
                            selectedCurrency
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {benefits.length && (
                    <div className="layout-row">
                      <div className="RemunerationOverview__Field RemunerationOverview__Field--Benefits">
                        <div className="RemunerationOverview__Label">
                          Additional Benefits
                        </div>
                        <div className="RemunerationOverview__Amount">
                          <ReactChips readOnly data={benefits} accent />
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

RemunerationOverview.propTypes = {
  remuneration: PropTypes.object,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any
};
