import angular from 'angular';
import { react2angular } from 'react2angular';
import { ExpandableTextarea } from './ExpandableTextarea';

export default angular
  .module('wc.components.inputs.expandableTextarea', [])
  // @ts-ignore
  .component(
    'reactWcExpandableTextarea',
    react2angular(ExpandableTextarea, [
      'focus',
      'model',
      'sendOnEnter',
      'placeholder',
      'innerRef',
      'onSubmit',
      'onChange',
      'className'
    ])
  );
