import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const BlockQuote = ({ title, text, className = '' }) => {
  return (
    <div className={`BlockQuote ${className}`}>
      {title && <h3 className="BlockQuote__Title">{title}</h3>}
      <div className="BlockQuote__QuoteContainer layout-row">
        <div className="BlockQuote__Icon BlockQuote__Icon__Start zmdi zmdi-quote" />
        <div className="BlockQuote__Text">{text}</div>
        <div className="BlockQuote__Icon BlockQuote__Icon__End zmdi zmdi-quote" />
      </div>
    </div>
  );
};

BlockQuote.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};
