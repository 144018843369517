import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { BlockQuote } from '../../block-quote/BlockQuote';

export const QAndA = ({ question, answer, separator = false }) => {
  return (
    <React.Fragment>
      {separator && <div className="QAndA__Separator" />}
      <div className="QAndA">
        <h3 className="QAndA__Question">{question}</h3>
        <BlockQuote text={answer} className="QAndA__Answer" />
      </div>
    </React.Fragment>
  );
};

QAndA.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  separator: PropTypes.bool
};
