import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

import { passwordStrength, defaultOptions } from 'pathwell';

class PasswordInputController {
  /*@ngInject*/
  constructor($element, $scope, AuthenticationConfig) {
    this.options = Object.assign(
      {},
      defaultOptions,
      AuthenticationConfig.PASSWORD_STRENGTH_OPTIONS
    );
    this.required = $element[0].hasAttribute('required');
    this.fieldName = 'passwordInput';

    $scope.$watch(
      () => {
        return this.field ? this.field.$viewValue : null;
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.strength = passwordStrength(this.field.$viewValue) * 100;
          this.setAdviceAndHints();
        }
      }
    );

    if (this.form) {
      this.field = this.form[this.fieldName] || {};
    }

    $scope.$watch(
      () => {
        if (this.form) {
          return this.field;
        }
      },
      () => {
        if (this.form) {
          this.field = this.form[this.fieldName];
        }
      },
      false
    );
  }

  setAdviceAndHints() {
    switch (this.strength) {
      case 50:
        this.breakTime = `5 minutes`;
        this.weakReason = 'it uses few types of characters';
        this.advice =
          'Use a mixture of digits, uppercase letters, and special characters';
        break;
      case 75:
        this.breakTime = `10 minutes`;
        this.weakReason = 'it uses a very common pattern';
        this.advice =
          'Do not use existing words, and try to put numbers and special characters at unexpected places';
        break;
      default:
        this.breakTime = `a second`;
        this.weakReason = 'it is too short';
        this.advice = 'Choose a longer password';
        break;
    }
  }
}

/*@ngInject*/
export default angular
  .module('wcCommon.components.inputs.passwordInput', [])
  .component('wcPasswordInput', {
    bindings: {
      placeholder: '@',
      password: '=',
      form: '=?',
      showAdvice: '<'
    },
    controller: PasswordInputController,
    templateUrl: templateUrl
  });
