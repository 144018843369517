import angular from 'angular';
import { react2angular } from 'react2angular';
import { ProfessionalSummary } from './ProfessionalSummary';

export default angular
  .module('wc.components.ProfessionalSummary', [])
  // @ts-ignore
  .component(
    'reactProfessionalSummary',
    react2angular(ProfessionalSummary, ['member', 'clampLines'])
  );
