class RedirectService {
  /*@ngInject*/
  constructor(AuthenticationService, $state, $sessionStorage) {
    this.AuthenticationService = AuthenticationService;
    this.$state = $state;
    this.$sessionStorage = $sessionStorage;

    this.ready = this.AuthenticationService.getAuthenticatedUser();
  }

  redirectIfNoPreviewAccess() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isLoggedIn() ||
        this.AuthenticationService.isMember()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfAlreadyChosenPassword() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isLoggedIn() ||
        this.AuthenticationService.hasChosenPassword()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfAlreadyLoggedIn() {
    // Only check the Token existence, not the User. This is because the AuthenticationService can
    // fail to GET the User because of a 50x, even though the app should still consider the user
    // as logged in (since they have a valid token).
    if (this.AuthenticationService.hasSession()) {
      if (
        this.AuthenticationService.isMember() ||
        this.AuthenticationService.isClient()
      ) {
        return this._doRedirect();
      }
    }
  }

  redirectIfNotClient() {
    return this.ready.then(() => {
      if (!this.AuthenticationService.isClient()) {
        return this._doRedirect();
      }
    });
  }

  redirectIfShouldntAcceptTerms() {
    return this.ready.then(() => {
      if (!this.AuthenticationService.shouldAcceptTerms()) {
        return this._doRedirect();
      }
    });
  }

  redirectClientIfNeeded() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isClient() ||
        this.AuthenticationService.shouldAcceptTerms()
      ) {
        return this._doRedirect();
      }
      if (
        !this.$sessionStorage.hasSeenLegislationAlert &&
        !this.AuthenticationService.hasAcceptedLegislation()
      ) {
        return this._redirectToLegislationAcceptPage();
      }
    });
  }

  redirectIfNotMember() {
    return this.ready.then(() => {
      if (!this.AuthenticationService.isMember()) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotCompleteClient() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isClient() ||
        !this.AuthenticationService.isProfileComplete()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotCompleteClientOrNotAcceptedLegislation() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isClient() ||
        !this.AuthenticationService.isProfileComplete()
      ) {
        return this._doRedirect();
      }
      if (!this.AuthenticationService.hasAcceptedLegislation()) {
        return this._redirectToLegislationAcceptPage();
      }
    });
  }

  redirectIfNotCompleteClientOrAcceptedLegislation() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isClient() ||
        !this.AuthenticationService.isProfileComplete() ||
        this.AuthenticationService.hasAcceptedLegislation() ||
        this.AuthenticationService.shouldAcceptTerms()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotFullClient() {
    return this.ready.then(() => {
      if (
        this.AuthenticationService.isClient() &&
        this.AuthenticationService.isLimitedClient()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfMessagingDisabled() {
    return this.ready.then(() => {
      if (
        this.AuthenticationService.isClient() &&
        this.AuthenticationService.isMessagingDisabled()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotCompleteMember() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isMember() ||
        !this.AuthenticationService.isProfileComplete()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotCompleteMemberOrOwnProfile(id) {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isMember() ||
        !this.AuthenticationService.isProfileComplete() ||
        this.AuthenticationService.isOwnMemberProfile(id)
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotClientOrAlreadyComplete() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isClient() ||
        !this.AuthenticationService.hasChosenPassword() ||
        this.AuthenticationService.isProfileComplete()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotMemberOrAlreadyComplete() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isMember() ||
        !this.AuthenticationService.hasChosenPassword() ||
        this.AuthenticationService.isProfileComplete()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotMemberPublic() {
    return this.ready.then(() => {
      if (
        !this.AuthenticationService.isMember() ||
        !this.AuthenticationService.isProfileComplete() ||
        !this.AuthenticationService.isMemberPublic()
      ) {
        return this._doRedirect();
      }
    });
  }

  redirectIfNotLoggedIn() {
    return this.ready.then(() => {
      if (!this.AuthenticationService.isLoggedIn()) {
        return this._doRedirect();
      }
    });
  }

  _doRedirect() {
    return this.AuthenticationService.navigateToDefaultState();
  }

  _redirectToLegislationAcceptPage() {
    return this.AuthenticationService.redirectToLegislationAcceptPage();
  }
}

export default angular
  .module('wcCommon.services.redirect', [])
  .service('RedirectService', RedirectService);
