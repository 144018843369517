import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const getMemberGenderName = memberGender => {
  switch (memberGender) {
    case 'F':
      return 'Female';
    case 'M':
      return 'Male';
    case 'O':
      return 'Other';
    default:
      return 'N/A';
  }
};

export const MemberProfilePersonal = ({ member }) => {
  return (
    <article className="Separator WcMemberProfilePersonal">
      <div>
        <div className="layout-row">
          {member.gender && (
            <div className="flex">
              <h2 className="WcMemberProfileContent__Subheading">Gender</h2>
              {getMemberGenderName(member.gender)}
            </div>
          )}
          {member.ethnicity && (
            <div className="flex">
              <h2 className="WcMemberProfileContent__Subheading">Ethnicity</h2>
              <p>{member.ethnicity.name}</p>
            </div>
          )}
        </div>
        {member.countryOfOrigin && (
          <div>
            <h2 className="WcMemberProfileContent__Subheading">
              Country of Origin
            </h2>
            <p>{member.countryOfOrigin.name}</p>
          </div>
        )}
      </div>
    </article>
  );
};

MemberProfilePersonal.propTypes = {
  member: PropTypes.object
};
