/**
 * <img ng-src="..." wc-missing-image="http://placeholder/path/image.jpg">
 *
 * If ng-src exists, but returns 404 or other HTTP errors, the wc-missing-image attribute value
 * will be used to replace the `src` attribute of the element.
 *
 * Based on:
 * http://stackoverflow.com/questions/16310298/if-a-ngsrc-path-resolves-to-a-404-is-there-a-way-to-fallback-to-a-default
 */
function MissingImage() {
  return {
    restrict: 'A',
    link(scope, elem, attrs) {
      elem.on('error', () => {
        if (attrs.src !== attrs.wcMissingImage) {
          setSrc();
        }
      });

      attrs.$observe('ngSrc', value => {
        if (!value && attrs.wcMissingImage) {
          setSrc();
        }
      });

      attrs.$observe('wcMissingImage', value => {
        if (value && !attrs.ngSrc) {
          setSrc();
        }
      });

      function setSrc() {
        attrs.$set('src', attrs.wcMissingImage);
        elem.addClass('WcMissingImage');
      }
    }
  };
}

MissingImage.NAME = 'wcMissingImage';

export default MissingImage;
