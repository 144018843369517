import FileUploadController from '../BaseController';
import { validate } from './validation';
import templateUrl from './template.html';
import './styles.scss';

class DocumentUploadController extends FileUploadController {
  /*@ngInject*/
  constructor($element, $scope, API) {
    super();
    this.API = API;

    this.icons = {
      READY: 'cv',
      UPLOADED: 'check'
    };

    $scope.$watch(
      () => this.currentDocument,
      () => {
        if (this.currentDocument) {
          this.setUploaded();
        }
      }
    );

    this.inputElement = $($element).find('.WcDocumentUpload__Input');
  }

  setUploaded() {
    super.setUploaded();
  }

  handleError(error) {
    if (
      angular.isDefined(error) &&
      angular.isDefined(error.data) &&
      angular.isDefined(error.data.file) &&
      error.data.file.length > 0
    ) {
      this.errorMessage = error.data.file[0];
    } else {
      this.errorMessage = 'An unknown error occurred during upload.';
    }

    this.model = undefined;
    this.setReady();
  }

  validate(file) {
    let validationInfo = validate(file);

    if (!validationInfo.isValid) {
      this.errorMessage = validationInfo.error;
    }

    return validationInfo.isValid;
  }

  onFileUploadClick() {
    this.inputElement.trigger('click');
  }

  onUpload(files) {
    if (!files || files.length === 0) {
      return;
    }

    this.setUploading();

    this.uploadFn({ file: files[0] })
      .then(this.setUploaded.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export default angular
  .module('wcCommon.components.inputs.documentUpload', [])
  .component('wcDocumentUpload', {
    bindings: {
      captions: '=',
      currentDocument: '@?',
      hint: '@',
      uploadFn: '&'
    },
    controller: DocumentUploadController,
    templateUrl: templateUrl
  });
