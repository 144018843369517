import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

const config = {
  template: 'Y __, M __',
  minValue: 1,
  trim: 'all'
};

function momentDurationFormatWrapper() {
  momentDurationFormatSetup(moment);
  moment.duration.fn.format.defaults = config;
}

/*@ngInject*/
export default angular
  .module('wcCommon.components.momentDurationFormatWrapper', [])
  .run(momentDurationFormatWrapper);
