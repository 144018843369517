import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ProfessionalSummary = ({ member, clampLines }) => {
  const [
    professionalSummaryExpanded,
    setProfessionalSummaryExpanded
  ] = useState(false);

  const lines = clampLines ? clampLines : 2;
  const clampStyle = 'Clamp-' + lines;

  return (
    <React.Fragment>
      {member.professionalSummary && (
        <div
          className="WcCommonMemberProfileOverviewPersonal"
          onClick={() => setProfessionalSummaryExpanded(state => !state)}
        >
          {member.professionalSummary && (
            <div
              className={`WcCommonMemberProfileOverview__ProfessionalSummary ${
                !professionalSummaryExpanded
                  ? 'WcCommonMemberProfileOverview__ProfessionalSummary--clamped'
                  : ''
              } ${clampStyle}`}
            >
              {professionalSummaryExpanded ? (
                <div className="WcCommonMemberProfileOverview__ProfessionalSummaryText">
                  {member.professionalSummary}
                </div>
              ) : (
                <div
                  className="WcCommonMemberProfileOverview__ProfessionalSummaryText"
                  // eslint-disable-next-line
                  clamp={lines}
                >
                  {member.professionalSummary}
                </div>
              )}
            </div>
          )}
          {!member.professionalSummary && (
            <div className="WcCommonMemberProfileOverview__ProfessionalSummary--emptySummary">
              Boost your visibility in client search results by writing a
              professional summary. Other members will also be able to read your
              summary to learn more about your career.
            </div>
          )}
        </div>
      )}
      {member.professionalSummary && (
        <div
          className="WcCommonMemberProfileOverview__ProfessionalSummaryMoreButton"
          onClick={() => setProfessionalSummaryExpanded(state => !state)}
        >
          {professionalSummaryExpanded ? (
            <span className="zmdi zmdi-chevron-up" />
          ) : (
            <span className="zmdi zmdi-chevron-down" />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

ProfessionalSummary.propTypes = {
  member: PropTypes.object,
  clampLines: PropTypes.number
};
