import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileEducation } from './MemberProfileEducation';

export default angular
  .module('wc.components.MemberProfileEducation', [])
  // @ts-ignore
  .component(
    'reactMemberProfileEducation',
    react2angular(MemberProfileEducation, ['member'])
  );
