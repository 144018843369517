import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileDocuments } from './MemberProfileDocuments';

export default angular
  .module('wc.components.MemberProfileDocuments', [])
  // @ts-ignore
  .component(
    'reactMemberProfileDocuments',
    react2angular(
      MemberProfileDocuments,
      ['longlist', 'documents'],
      ['DocumentDownloadService']
    )
  );
