import angular from 'angular';
import { MAX_INTEGER } from '../NumericalLimits';

function maxNumber() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.max = function(modelValue) {
        return modelValue <= MAX_INTEGER;
      };
    }
  };
}

maxNumber.NAME = 'dfMaxNumber';

export default angular
  .module('dfComponents.validators.maxNumber', [])
  .directive(maxNumber.NAME, maxNumber);
