import numeral from 'numeral';

export const filterLargeNumbers = (
  input,
  significantDigits = '',
  currency = ''
) => {
  if (input === null) {
    input = 0;
  }

  let languageData = numeral.languageData();
  languageData.abbreviations.billion = 'bn';
  numeral.language('en', languageData);

  if (angular.isString(input)) {
    input = parseFloat(String(input).replace(/,/g, ''));
  }

  if (input < 1000) {
    return currency + input;
  }

  let formatted = numeral(input).format('0[,]'); // if no significantDigits (and currency), do not round

  if (significantDigits) {
    let zeros = '[.]' + '0'.repeat(significantDigits);
    formatted = numeral(input).format('0' + zeros + 'a');
  }

  return currency + formatted;
};
