import React from 'react';
import PropTypes from 'prop-types';
import { ReactChips } from '../../../../../../desktop/components/react/ReactChips/ReactChips';
import { removeChildRegions } from '../../../../../services/Regions';

export const MemberProfileWorkEligibility = ({ member }) => {
  return (
    <article className="Separator">
      <h2 className="WcMemberProfileContent__Subheading">Work Eligibility</h2>
      <div>
        <ReactChips
          data={removeChildRegions(member.eligibleRegions)}
          readOnly
          accent
        />
      </div>
    </article>
  );
};

MemberProfileWorkEligibility.propTypes = {
  member: PropTypes.object
};
