const preferredCurrencyKey = 'defaultCurrency';

class PreferredCurrencyService {
  /*@ngInject*/
  constructor($localStorage) {
    this.$localStorage = $localStorage;
  }

  getPreferredCurrency(currencies, defaultCurrency) {
    const defaultCode = defaultCurrency || 'USD';

    if (this.$localStorage[preferredCurrencyKey]) {
      return currencies.filter(
        c => c.code === this.$localStorage[preferredCurrencyKey].code
      )[0];
    }

    return currencies.filter(c => c.code === defaultCode)[0];
  }

  setPreferredCurrency(currency) {
    this.$localStorage[preferredCurrencyKey] = currency;
  }
}

export default angular
  .module('wcCommon.services.preferredCurrency', [])
  .service('PreferredCurrencyService', PreferredCurrencyService);
