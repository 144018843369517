class GoogleAnalyticsService {
  /*@ngInject*/
  constructor(Analytics) {
    this.Analytics = Analytics;
  }

  setUserId(userId) {
    this.Analytics.set('dimension1', String(userId));
    this.Analytics.set('userId', userId);
  }

  clearUserId() {
    this.Analytics.set('dimension1');
    this.Analytics.set('userId');
  }
}

/* @ngInject */
export default angular
  .module('wcCommon.services.googleAnalytics', [])
  .service('GoogleAnalyticsService', GoogleAnalyticsService);
