import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfilePersonal } from './MemberProfilePersonal';

export default angular
  .module('wc.components.MemberProfilePersonal', [])
  // @ts-ignore
  .component(
    'reactMemberProfilePersonal',
    react2angular(MemberProfilePersonal, ['member'])
  );
