import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CompanyLogo } from '../../../company-logo/CompanyLogo';
import './styles.scss';

const dateDiff = (start, end = {}) => {
  let diff = moment(end).diff(moment(start), true);
  if (diff < 0) {
    diff *= -1;
  }

  let duration = moment.duration(diff);
  return duration.format ? duration.format() : '';
};

const getPositionType = position => {
  if (position.positionType) {
    return position.positionType.displayName
      ? position.positionType.displayName
      : position.positionType;
  }
};

export const MinimalPositionOverview = ({ position }) => {
  return (
    <React.Fragment>
      <div className="WcMinimalPositionOverview__SummaryText">
        <h3 className="WcMinimalPositionOverview__InternalJobTitle">
          {position.internalJobTitle}
        </h3>
        <div className="WcMinimalPositionOverview__RoleInfo">
          <span className="WcMinimalPositionOverview__CompanyName">
            {position.company.name}{' '}
          </span>
          {position.city && (
            <span className="WcMinimalPositionOverview__Separator">|</span>
          )}
          <span className="WcMinimalPositionOverview__City">
            {' '}
            {position.city}{' '}
          </span>
          <span className="WcMinimalPositionOverview__Separator">|</span>
          <span className="WcMinimalPositionOverview__PositionType">
            {' '}
            {getPositionType(position)}
          </span>
        </div>
        <div className="WcMinimalPositionOverview__Date">
          {position.startDate ? (
            <span>{moment(position.startDate).format('MMM YYYY')} </span>
          ) : (
            <span>Unknown </span>
          )}
          -
          {position.endDate ? (
            <span> {moment(position.endDate).format('MMM YYYY')} </span>
          ) : position.current ? (
            <span> Current </span>
          ) : (
            <span> Unknown </span>
          )}
          {position.startDate && position.endDate && (
            <span>
              <span className="WcMinimalPositionOverview__Separator">|</span>{' '}
              {dateDiff(position.startDate, position.endDate)}
            </span>
          )}
          {position.startDate && position.current && !position.endDate && (
            <span>
              <span className="WcMinimalPositionOverview__Separator">|</span>{' '}
              {dateDiff(position.startDate)}
            </span>
          )}
        </div>
      </div>
      <div className="WcMinimalPositionOverview__CompanyLogoWrapper">
        <CompanyLogo src={position.company.image} />
      </div>
    </React.Fragment>
  );
};

MinimalPositionOverview.propTypes = {
  position: PropTypes.object.isRequired
};
