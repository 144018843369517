/*
 * Service used for shared state across messenger components.
 */

export const MESSAGES_CHANGED_EVENT = 'MESSAGES_CHANGED';

export default class MessengerService {
  /*@ngInject*/
  constructor(
    $log,
    $q,
    $rootScope,
    $state,
    $stateParams,
    featureFlags,
    ChatService,
    $window,
    EnvConfig
  ) {
    this.$log = $log;
    this.EnvConfig = EnvConfig;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.ChatService = ChatService;
    this.$q = $q;
    this.unreadCount = 0;
    this.currentMessages = null;

    if (!featureFlags.isOn('messaging')) {
      $log.error(
        '[Messenger] Attempted to init messenger service, but feature is disabled!'
      );
      return;
    }

    $window.onbeforeunload = () => {
      this.logout();
    };

    // Public promise to be able too hook into the service when conversations are ready
    this._conversationsDeferred = this.$q.defer();
    this.conversationsPromise = this._conversationsDeferred.promise;

    // Public promise to be able to hook when messenger is ready
    this._serviceReadyDeferred = this.$q.defer();
    this.serviceReadyPromise = this._serviceReadyDeferred.promise;
  }

  getConversationState() {
    return this.conversationState;
  }

  getListState() {
    return this.listState;
  }

  getMessengerStates() {
    return [this.getConversationState(), this.getListState()];
  }

  getConversations() {
    return this.conversations;
  }

  isMessengerState(stateName) {
    return this.messengerStates.indexOf(stateName) !== -1;
  }

  init(user, config) {
    if (!user || !config || !config.conversation || !config.list) {
      throw new Error('You must set the user and states');
    }

    this.conversationState = config.conversation;
    this.listState = config.list;
    this.redirectListState = config.redirectListState;

    this.messengerStates = this.getMessengerStates();

    this.conversations = [];
    this.unreadCount = 0;
    this.currentConversation = null;
    this.currentMessages = null;

    this.user = user;

    this.ChatService.init(user.chatId);
    this.ChatService.onReady(this.messengerInitSuccess.bind(this));

    this._setupWatchers();
  }

  _setupWatchers() {
    this.$rootScope.$on(
      '$stateChangeSuccess',
      this._setCurrentConversationFromStateParam.bind(this)
    );
  }

  logout(callback) {
    this._conversationsDeferred = this.$q.defer();
    this.conversationsPromise = this._conversationsDeferred.promise;
    this._serviceReadyDeferred = this.$q.defer();
    this.serviceReadyPromise = this._serviceReadyDeferred.promise;

    this.conversations = [];
    this.unreadCount = 0;
    this.currentConversation = null;
    this.currentMessages = null;

    this.ChatService.logout(callback);
  }

  eventListener(eventType, eventData) {
    switch (eventType) {
      case 'channelAdded':
        if (
          !this.ChatService.userId ||
          !eventData.createdBy ||
          eventData.createdBy !== this.ChatService.userId
        ) {
          this.loadConversations();
        }
        break;
      case 'messageAdded':
        this.refreshUnreadConversationsCount();
        this.triggerMessageChanged();
        break;
      case 'tokenAboutToExpire':
      case 'tokenExpired':
        this.ChatService.refreshToken();
        break;
    }
  }

  messengerInitSuccess(client) {
    this.$log.info('Chat initialized successfully');
    client.on('messageAdded', this.eventListener.bind(this, 'messageAdded'));
    client.on('channelAdded', this.eventListener.bind(this, 'channelAdded'));
    client.on(
      'tokenAboutToExpire',
      this.eventListener.bind(this, 'tokenAboutToExpire')
    );
    client.on('tokenExpired', this.eventListener.bind(this, 'tokenExpired'));
    this._serviceReadyDeferred.resolve();
    this.loadConversations();
  }

  loadConversations() {
    return this.ChatService.refreshConversations(
      this.setConversations.bind(this)
    );
  }

  setConversations(data) {
    this.conversations = data.conversations;
    this.unreadCount = data.unread;
    this._conversationsDeferred.resolve(data.conversations);
    this.$rootScope.$apply();
  }

  async getRecipient(conversation) {
    const members = await conversation.getMembers();
    this.members = members;
    const users = await Promise.all(
      members.map(async member => await member.getUser())
    );
    const recipient = users.find(
      member => member.identity !== this.user.chatId
    );
    return recipient;
  }

  getUser() {
    return this.user;
  }

  redirectToLatestConversation(conversations) {
    if (conversations && conversations.length > 0) {
      this.redirectToConversation({
        conversation: conversations[0]
      });
    }
  }

  _setCurrentConversationFromStateParam(ev, to, toParams, from) {
    // Reset currentConversation when leaving message pages
    if (
      from &&
      from.name === this.conversationState &&
      to.name !== this.conversationState
    ) {
      this.leaveConversations();
    }
    // Set currentConversation when visiting a conversation
    if (this.conversationState === to.name && toParams.id) {
      this.conversationsPromise.then(
        this.setCurrentConversation.bind(this, toParams.id)
      );
    }
    // Redirect to latest conversation when going to message list page (desktop only)
    if (
      this.listState === to.name &&
      this.conversations &&
      this.redirectListState
    ) {
      this.redirectToLatestConversation(this.conversations);
    }
  }

  setCurrentConversation(conversationId) {
    if (!this.conversations) {
      this.currentConversation = null;
      this.currentMessages = null;
      return;
    }

    let currentConversation = this.conversations.find(conversation => {
      return conversation.sid === conversationId;
    });

    if (currentConversation) {
      this.setConversation(currentConversation);
    }
  }

  async getConversationMessages(conversation) {
    const messages = await conversation.getMessages();
    return messages.items;
  }

  async setConversation(currentConversation) {
    // This is pretty tricky: if we already have a conversation, and it looks like we're trying to
    // switch to the same one, we should exit early to prevent messages reloading.
    //
    // Fixes a bug where sending a new message causes entire message list to reload (bad UX).
    //
    // The Messenger service is becoming a good re-think/refactor candidate now. Lots of tracked
    // state and complex execution flow going on. Classic example of brittle/bug-prone low-level JS
    if (
      !this.currentConversation ||
      currentConversation.sid !== this.currentConversation.sid
    ) {
      this.currentMessages = null;
      this.currentConversation = currentConversation;
    }

    this._updateMessages();

    if (this.currentConversation) {
      this.currentConversation.on(
        'messageAdded',
        this._updateMessages.bind(this)
      );
    }

    this.setCurrentConversationRead();
  }

  async setCurrentConversationRead() {
    if (this.currentConversation) {
      await this.currentConversation.setAllMessagesConsumed();
      this.refreshUnreadConversationsCount();
    }
  }

  triggerMessageChanged() {
    this.$rootScope.$broadcast(MESSAGES_CHANGED_EVENT);
  }

  leaveConversations() {
    this.currentConversation = null;
    this.currentMessages = null;
  }

  async refreshUnreadConversationsCount() {
    this.unreadCount = this.conversations
      ? await this.ChatService.getUnreadCount(this.conversations)
      : 0;
    this.$rootScope.$apply();
  }

  getUnreadConversationsCount() {
    return this.unreadCount;
  }

  messageUser(userId) {
    this.ChatService.startConversation(userId).then(conversation => {
      this.loadConversations().then(() =>
        this.redirectToConversation(conversation)
      );
    });
  }

  redirectToConversation(data) {
    if (data.userId) {
      this.$state.go(this.conversationState, {
        id: data.conversation.sid,
        userId: data.userId
      });
    } else {
      this.$state.go(this.conversationState, {
        id: data.conversation.sid
      });
    }
  }

  async _updateMessages() {
    if (!this.currentConversation) {
      return;
    }

    this.currentMessages = await this.getConversationMessages(
      this.currentConversation
    );

    this.triggerMessageChanged();
  }
}

MessengerService.NAME = 'MessengerService';
