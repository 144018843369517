import angular from 'angular';
import template from './MonthInput.html';
import './MonthInput.scss';

let MonthInputDirective = () => {
  return {
    templateUrl: template,
    restrict: 'E',
    scope: {
      fieldName: '@?',
      form: '=?',
      label: '@?',
      max: '<?',
      maxMessage: '@?',
      min: '<?',
      minMessage: '@?',
      model: '='
    },
    link: (scope, elem, attrs) => {
      scope.required = attrs.hasOwnProperty('required');
      scope.fieldName = scope.fieldName ? scope.fieldName : 'monthInput';
      scope.monthField = scope.fieldName + 'Month';
      scope.yearField = scope.fieldName + 'Year';

      function getFullYearFromYearMonth(year) {
        let now = new Date();
        let nowShortYear = parseInt(
          now
            .getFullYear()
            .toString()
            .slice(-2)
        );
        // Add two years from now. Anything > today year + 2 will be in 19xx
        let splitYear = nowShortYear + 2;
        let yearString = ('0' + year).slice(-2);
        if (year > splitYear) {
          return parseInt('19' + yearString);
        }
        return parseInt('20' + yearString);
      }

      function updateMonthYear(dateObj) {
        if (dateObj) {
          if (!dateObj.getFullYear) {
            dateObj = new Date(dateObj);
          }
          scope.month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
          scope.year = dateObj
            .getFullYear()
            .toString()
            .slice(-2);
          scope.validateMinMax();
        }
      }

      let modelWatch = scope.$watch('model', newValue => {
        if (newValue) {
          updateMonthYear(newValue);
          modelWatch();
        }
      });

      scope.updateModel = () => {
        let year = parseInt(scope.year);
        let month = parseInt(scope.month);
        if (isNaN(year) || isNaN(month)) {
          scope.model = null;
          return;
        }
        if (
          angular.isNumber(month) &&
          angular.isNumber(year) &&
          angular.isDefined(scope.year) &&
          angular.isDefined(scope.month) &&
          scope.year !== '0' &&
          scope.month !== '0'
        ) {
          scope.model = new Date(
            getFullYearFromYearMonth(year, month),
            month - 1,
            15
          );
          scope.validateMinMax();
        }
      };

      scope.validateMinMax = () => {
        const modelDate = new Date(scope.model);
        scope.minMax = {
          min: !!scope.min && modelDate < new Date(scope.min),
          max: !!scope.max && modelDate > new Date(scope.max)
        };
      };
    }
  };
};

MonthInputDirective.NAME = 'monthInput';

export default angular
  .module('wcCommon.components.inputs.numerical.month', [])
  .directive('monthInput', MonthInputDirective);
