export const USER_TYPES = {
  ADMIN: 'ADM',
  CLIENT: 'CLI',
  MEMBER: 'MEM'
};

export const USER_TYPE_DISPLAY = {
  [USER_TYPES.ADMIN]: 'Admin',
  [USER_TYPES.CLIENT]: 'Client',
  [USER_TYPES.MEMBER]: 'Member'
};
