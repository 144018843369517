import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const BASE_UNIT = '%';
const BASE_SIZE = 100;

export const RemunerationGraph = ({ remuneration, rowLayout = 'false' }) => {
  const [dimension, setDimension] = useState('height');
  const [salary, setSalary] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [longTermCash, setLongTermCash] = useState(0);
  const [stockAndOptions, setStockAndOptions] = useState(0);
  const [otherFinancial, setOtherFinancial] = useState(0);
  const [displayLegend, setDisplayLegend] = useState(false);

  useEffect(() => {
    setDimension(rowLayout ? 'width' : 'height');

    const amounts = [
      remuneration.salaryAmount,
      remuneration.bonusAmount,
      remuneration.longTermCashAmount,
      remuneration.stockAndOptionsAmount,
      remuneration.otherFinancialAmount
    ];

    const totalCompensationAmount = amounts.reduce((a, b) => {
      a = parseFloat(a) || 0;
      b = parseFloat(b) || 0;
      return a + b;
    }, 0.0);

    if (totalCompensationAmount > 0) {
      setSalary(
        (remuneration.salaryAmount / totalCompensationAmount) * BASE_SIZE
      );
      setBonus(
        (remuneration.bonusAmount / totalCompensationAmount) * BASE_SIZE
      );
      setLongTermCash(
        (remuneration.longTermCashAmount / totalCompensationAmount) * BASE_SIZE
      );
      setStockAndOptions(
        (remuneration.stockAndOptionsAmount / totalCompensationAmount) *
          BASE_SIZE
      );
      setOtherFinancial(
        (remuneration.otherFinancialAmount / totalCompensationAmount) *
          BASE_SIZE
      );

      setDisplayLegend(true);
    } else {
      setSalary(0);
      setBonus(
        (remuneration.bonusAmount / totalCompensationAmount) * BASE_SIZE
      );
      setLongTermCash(0);
      setStockAndOptions(0);
      setOtherFinancial(0);

      setDisplayLegend(false);
    }
  }, [remuneration, rowLayout]);

  return (
    <div>
      <div className="RemunerationGraph">
        <div
          className={`RemunerationGraph__Graph ${
            rowLayout ? 'RemunerationGraphRow' : 'RemunerationGraphColumn'
          }`}
        >
          <div
            className="RemunerationGraph__Bar RemunerationGraph__Salary"
            style={{ [dimension]: salary + BASE_UNIT }}
          />
          <div
            className="RemunerationGraph__Bar RemunerationGraph__Bonus"
            style={{ [dimension]: bonus + BASE_UNIT }}
          />
          <div
            className="RemunerationGraph__Bar RemunerationGraph__LongTermCash"
            style={{ [dimension]: longTermCash + BASE_UNIT }}
          />
          <div
            className="RemunerationGraph__Bar RemunerationGraph__StockAndOptions"
            style={{ [dimension]: stockAndOptions + BASE_UNIT }}
          />
          <div
            className="RemunerationGraph__Bar RemunerationGraph__Other"
            style={{ [dimension]: otherFinancial + BASE_UNIT }}
          />
        </div>
      </div>

      {displayLegend && (
        <div className="RemunerationGraph__Legend">
          <span className="RemunerationGraph__Legend__Element">
            <span className="RemunerationGraph__Block RemunerationGraph__Salary" />{' '}
            Basic Salary
          </span>
          <span className="RemunerationGraph__Legend__Element">
            <span className="RemunerationGraph__Block RemunerationGraph__Bonus" />{' '}
            Cash Bonus
          </span>
          <span className="RemunerationGraph__Legend__Element">
            <span className="RemunerationGraph__Block RemunerationGraph__LongTermCash" />{' '}
            Long-Term Cash
          </span>
          <span className="RemunerationGraph__Legend__Element">
            <span className="RemunerationGraph__Block RemunerationGraph__StockAndOptions" />{' '}
            Stock / Options
          </span>
          <span className="RemunerationGraph__Legend__Element">
            <span className="RemunerationGraph__Block RemunerationGraph__Other" />{' '}
            Other
          </span>
        </div>
      )}
    </div>
  );
};

RemunerationGraph.propTypes = {
  remuneration: PropTypes.object,
  rowLayout: PropTypes.bool
};
