class S3FileUploadService {
  /*@ngInject*/
  constructor($q, Restangular, API) {
    this.$q = $q;
    this.Restangular = Restangular;
    this.API = API;

    this.policyEndpoint = API.ProfilePictureUploadPolicy;
  }

  retrieveUploadUrl() {
    return this.policyEndpoint.get({ content_type: this.contentType });
  }

  setUploadMetadata(uploadMetadata) {
    this.uploadMetadata = uploadMetadata;
  }

  doUpload(file, contentType) {
    let formData = new FormData(); // ie9+
    formData.append('key', this.uploadMetadata.key);
    formData.append('acl', this.uploadMetadata.acl);
    if (this.uploadMetadata.algorithm) {
      formData.append('Policy', this.uploadMetadata.policy);
      formData.append('X-Amz-Algorithm', this.uploadMetadata.algorithm);
      formData.append('X-Amz-Signature', this.uploadMetadata.signature);
      formData.append('X-Amz-Credential', this.uploadMetadata.credential);
      formData.append('X-Amz-Date', this.uploadMetadata.date);
    } else {
      formData.append('AWSAccessKeyID', this.uploadMetadata.AWSAccessKeyId);
      formData.append('policy', this.uploadMetadata.policy);
      formData.append('signature', this.uploadMetadata.signature);
    }
    formData.append('Content-Type', contentType);
    formData.append('file', file);

    return this.Restangular.oneUrl(
      this.uploadMetadata.url,
      this.uploadMetadata.url
    )
      .withHttpConfig({ transformRequest: angular.identity })
      .customPOST(formData, '', {}, { 'Content-Type': undefined });
  }

  setPublicUrl() {
    this.publicUrl =
      this.uploadMetadata.url.split('?')[0] + this.uploadMetadata.key;
  }

  uploadFile(file) {
    let url = this.$q.defer();

    this.retrieveUploadUrl()
      .then(this.setUploadMetadata.bind(this))
      .then(this.doUpload.bind(this, file, file.type))
      .then(this.setPublicUrl.bind(this))
      .then(() => {
        url.resolve(this.publicUrl);
        this.API.imageUploadSuccess();
      })
      .catch(() => {
        url.reject();
        this.API.imageUploadFailure();
      });

    return url.promise;
  }
}

export default angular
  .module('wcCommon.services.s3Upload', [])
  .service('S3FileUploadService', S3FileUploadService);
