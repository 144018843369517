export default class TextareaController {
  /*@ngInject*/
  constructor($attrs, $scope) {
    this.required = 'required' in $attrs;

    // This is the exact same behaviour as the 'maxlength' html attribute.
    // However for some reason using 'maxlength' causes 'Maximum call stack size exceeded at RegExp' error
    // when the text is entered fast.
    $scope.$watch(
      () => this.ngModel,
      (current, old) => {
        if (current && current.length > this.maxLength) {
          this.ngModel = old;
        }
      }
    );
  }
}
