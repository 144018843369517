function ConfigService(envConfig) {
  return envConfig;
}

/*@ngInject*/
/**
 * ConfigProvider
 *
 * Used in desktop app and mobile app to dynamically set the environment configuration and
 * the feature flags in `.config` steps.
 */
export default function ConfigProvider(
  $compileProvider,
  $locationProvider,
  $logProvider,
  featureFlagsProvider
) {
  let envConfig = {};

  this.setEnvConfig = function(config) {
    envConfig = config;
    featureFlagsProvider.setInitialFlags(config.featureFlags);
  };

  $locationProvider.html5Mode(true);

  const enableDebug = ConfigProvider.configName === 'local';
  $logProvider.debugEnabled(enableDebug);
  $compileProvider.debugInfoEnabled(enableDebug);

  this.$get = function() {
    return new ConfigService(envConfig);
  };
}

ConfigProvider.NAME = 'EnvConfig';
