import angular from 'angular';

import templateUrl from './template.html';

export default angular
  .module('wcCommon.components.inputs.messages.inputError', [])
  .component('wcInputError', {
    templateUrl: templateUrl,
    transclude: true,
    bindings: {
      errorMessages: '<'
    }
  });
