/*@ngInject*/
let API = (
  $log,
  EnvConfig,
  WcApiRestangular,
  WcFullResponseRestangular,
  WcResumeRestangular,
  $q
) => {
  let errorHandler = function defaultErrorHandler() {
    $log.warn(
      'API error handler was called, but no error handling function has been registered.',
      'Register a function with API.onError(callback), during a .run() block, for example.'
    );
  };

  let successHandler = function defaultSuccessHandler() {
    $log.warn(
      'API success handler was called, but no success handling function has been registered.',
      'Register a function with API.onSuccess(callback), during a .run() block, for example.'
    );
  };

  /**
   * Register an error handler function to be called on API request errors.
   *
   * The API service only supports one error handler function, so multiple calls to `onError` will
   * overwrite the existing callback. Generally `onError` should be called once in the app
   * lifecycle, in a `.run()` block, for example.
   *
   * Example usage:
   *
   * API.onError(function displayToastOnError(message) {
   *   ToastService.error(message);
   * });
   *
   * @param {function} callback   A callback function which accepts a single `message` parameter.
   */
  function onError(callback) {
    errorHandler = callback;
  }

  /**
   * Register a success handler function to be called when the API raises positive messages.
   *
   * The API service only supports one success handler function, so multiple calls to `onSuccess`
   * will overwrite the existing callback. Generally `onSuccess` should be called once in the app
   * lifecycle, in a `.run()` block, for example.
   *
   * Example usage:
   *
   * API.onSuccess(function displayToastOnSuccess(message) {
   *   ToastService.success(message);
   * });
   *
   * @param {function} callback   A callback function which accepts a single `message` parameter.
   */
  function onSuccess(callback) {
    successHandler = callback;
  }

  let ContactDetails = userId => {
    return WcApiRestangular.one('users', userId)
      .one('contact')
      .get();
  };

  let EmailPreferences = memberId => {
    return WcApiRestangular.one('members', memberId)
      .one('email-preferences')
      .get();
  };

  let EmailPreferencesList = memberId => {
    return WcApiRestangular.one('members', memberId).all('email-preferences');
  };

  let Longlist = longlistId => {
    return WcApiRestangular.one('longlists', longlistId);
  };

  function LonglistMembersFull(longlistId) {
    return WcFullResponseRestangular.one('longlists', longlistId).all(
      'members'
    );
  }

  function LonglistMembers(longlistId) {
    return WcApiRestangular.one('longlists', longlistId).all('members');
  }

  function LonglistContacts(longlistId) {
    return WcApiRestangular.one('longlists', longlistId).all('contacts');
  }

  function LonglistAccounts(longlistId) {
    return WcApiRestangular.one('longlists', longlistId).all('accounts');
  }

  let LonglistMember = (longlistId, memberId) => {
    return WcApiRestangular.one('longlists', longlistId).one(
      'members',
      memberId
    );
  };

  let LonglistSharing = (longlistId, userId) => {
    return WcApiRestangular.one('longlists', longlistId).one('clients', userId);
  };

  let Members = WcFullResponseRestangular.all('members');
  let MembersData = WcApiRestangular.all('members');

  let LonglistDocuments = longlistId => {
    return WcApiRestangular.one('longlists', longlistId).all('documents');
  };

  let LonglistCandidateDocuments = (longlistId, memberId) => {
    return WcApiRestangular.one('longlists', longlistId)
      .one('members', memberId)
      .all('documents');
  };

  let Notes = memberId => {
    return WcApiRestangular.one('members', memberId).all('notes');
  };

  let GroupJoinRequests = groupId => {
    return WcApiRestangular.one('groups', groupId).all('join-requests');
  };

  let GroupMembers = groupId => {
    return WcApiRestangular.one('groups', groupId).all('members');
  };

  let GroupMember = (groupId, memberId) => {
    return WcApiRestangular.one('groups', groupId).one('members', memberId);
  };

  let GroupPosts = groupId => {
    return WcApiRestangular.one('groups', groupId).all('posts');
  };

  let PostComments = (groupId, postId) => {
    return WcApiRestangular.one('groups', groupId)
      .one('posts', postId)
      .all('comments');
  };

  let Position = positionId => {
    return WcApiRestangular.one('positions', positionId);
  };

  let NonExecPosition = positionId => {
    return WcApiRestangular.all('positions').one(
      'non-exec-positions',
      positionId
    );
  };

  const Resumes = WcResumeRestangular.all('resumes');

  let User = WcApiRestangular.one('user');

  let handleError = message => {
    return error => {
      // 401 errors are handled elsewhere, by logging the user out.
      if (error.status === 401) {
        return;
      }

      logError(error);
      if (!message) {
        if (error && error.data && error.data.detail) {
          message = error.data.detail;
        } else if (error && error.data && error.data.non_field_errors) {
          message = error.data.non_field_errors.join('\n');
        } else {
          message = 'An error occurred';
        }
      }

      errorHandler(message);
    };
  };

  let logError = error => {
    if (
      error &&
      error.status &&
      error.statusText &&
      error.data &&
      error.data.detail &&
      error.config &&
      error.config.url
    ) {
      $log.error(
        `API error: ${error.data.detail} (${error.config.url} ${error.status} ${
          error.statusText
        })`
      );
    } else if (error && error.status && error.statusText) {
      $log.error(`API error: ${error.status} ${error.statusText}`);
    } else {
      $log.error(`Unexpected API error ${error}`, error);
    }
  };

  let getRestangularObjectForUrl = (name, url) => {
    return WcApiRestangular.oneUrl(name, url);
  };

  let getWithUrl = (name, url) => {
    return getRestangularObjectForUrl(name, url).get();
  };

  /*
   handleGet
   Handle a GET request by appending the result to an object's property

   params:
   - key: the  property of baseObject to be set
   - baseObject: the object in which the result should be stored
   - data: the result data from the request
   */
  let handleGet = (key, baseObject, data) => {
    baseObject[key] = data;
  };

  /*
   getWithUrlAndHandle

   params:
   - key: the  property of baseObject to be set.
   - urlSourceObject: the object that has property 'key' which includes the URL to get the data from
   - resultObject: the "this" to be used. Defaults to the urlSourceObject.
   - success: the success callback function. Defaults to API.handleGet
   */
  let getWithUrlAndHandle = (key, urlSourceObject, resultObject, success) => {
    let err = '';
    if (!urlSourceObject) {
      err = `[API.factory#getWithUrlAndHandle] You must pass in an existing object as urlSourceObject`;
      $log.warn(err);
      return Promise.reject(err);
    }
    if (!urlSourceObject[key]) {
      err = `[API.factory#getWithUrlAndHandle] Tried to access '${key}' which does not exist on passed in urlSourceObject`;
      $log.warn(err);
      return Promise.reject(err);
    }
    resultObject = resultObject ? resultObject : urlSourceObject;
    success = success ? success : handleGet.bind(this, key, resultObject);
    return getWithUrl(key, urlSourceObject[key]).then(success, handleError);
  };

  function restangularizeUrlFull(url) {
    if (url) {
      let name = url.replace(EnvConfig.apiURL, '');
      return WcFullResponseRestangular.oneUrl(name, url);
    }
  }

  function restangularizeUrl(url) {
    if (url) {
      let name = url.replace(EnvConfig.apiURL, '');
      return WcApiRestangular.oneUrl(name, url);
    }
  }

  function restangularizeWithAllUrl(url) {
    let name = url.replace(EnvConfig.apiURL, '');
    return WcApiRestangular.allUrl(name, url);
  }

  function restangularize(obj) {
    return restangularizeUrl(obj.url);
  }

  let listMyLonglists = () => {
    return WcApiRestangular.all('longlists').getList();
  };

  let retrieveMyProfile = () => {
    return User.get().then(retrieveProfile);
  };

  let retrieveMyRemuneration = () => {
    return retrieveMyProfile().then(_retrieveRemuneration);
  };

  let retrieveMyVisibility = profile => {
    return _retrieveVisibility(profile);
  };

  let retrieveMyAspirations = () => {
    return retrieveMyProfile().then(_retrieveAspirations);
  };

  let retrieveMyCovisibility = () => {
    return retrieveMyProfile().then(_retrieveCovisibility);
  };

  let objectListToIdsSerializerField = (objectList, idField = 'id') => {
    if (!objectList || objectList.length === 0) {
      return [];
    }
    return objectList ? objectList.map(o => o[idField]) : [];
  };

  let retrieveMyLonglistsEndpoint = () => {
    return User.get().then(user => {
      if (!user.longlists) {
        return null;
      }
      return restangularizeWithAllUrl(user.longlists);
    });
  };

  function retrieveLiveCompanies() {
    return WcApiRestangular.all('companies').getList({ is_live: 'True' }); // ;(
  }

  function retrieveIdentityToken() {
    return WcApiRestangular.all('messages')
      .one('identity-token')
      .get();
  }

  let retrieveProfile = user => {
    if (user.profile) {
      return restangularizeUrl(user.profile).get();
    } else {
      $log.error(
        'Could not retrieve profile: no hyperlink in user object!',
        user
      );
    }
  };

  function retrieveMyCurrentPosition() {
    return retrieveMyProfile().then(profile => {
      if (profile.currentPosition) {
        return restangularize(profile.currentPosition).get();
      } else {
        return null;
      }
    });
  }

  function listMyGroups(filters = null) {
    return retrieveMyProfile().then(profile => {
      if (profile && profile.groups) {
        return restangularizeWithAllUrl(profile.groups).getList(filters);
      } else {
        $log.error(
          'Could not list groups: no hyperlink in user object!',
          profile
        );
      }
    });
  }

  function listMyGroupsICanInviteTo() {
    return listMyGroups({ canInvite: true });
  }

  function imageUploadSuccess() {
    _imageUpload(true);
  }

  function imageUploadFailure() {
    _imageUpload(false);
  }

  function _imageUpload(success) {
    return WcApiRestangular.all('profiles')
      .all('profile-picture-upload-policy')
      .post({ success });
  }

  // Hyperlinked helper functions
  let _retrieveAspirations = profile => {
    if (profile.aspirations) {
      return restangularizeUrl(profile.aspirations).get();
    } else {
      $log.error(
        'Could not retrieve aspirations: no hyperlink in user object!',
        profile
      );
    }
  };

  let _retrieveCovisibility = profile => {
    if (profile.covisibility) {
      return restangularizeUrl(profile.covisibility).get();
    } else {
      $log.error(
        'Could not retrieve covisibility: no hyperlink in user object!',
        profile
      );
    }
  };

  let _retrieveLonglists = user => {
    if (user.longlists) {
      return restangularizeUrl(user.longlists).get();
    } else {
      $log.error(
        'Could not retrieve longlists: no hyperlink in user object!',
        user
      );
    }
  };

  let _retrieveRemuneration = profile => {
    if (profile.remuneration) {
      return restangularize(profile.remuneration).get();
    } else {
      $log.error(
        'Could not retrieve remuneration: no hyperlink in user object!',
        profile
      );
    }
  };

  let _retrieveVisibility = profile => {
    if (profile.visibility) {
      return restangularizeUrl(profile.visibility).get();
    } else {
      return $q.resolve(null);
    }
  };

  return {
    onError: onError,
    onSuccess: onSuccess,

    Accounts: WcApiRestangular.all('accounts'),
    Address: WcApiRestangular.all('addresses'),
    Answer: WcApiRestangular.all('answers'),
    AreasOfResponsibility: WcApiRestangular.all('areas-of-responsibility'),
    Aspirations: WcApiRestangular.all('aspirations'),
    Authentication: WcApiRestangular.all('auth').all('token'),
    Cities: WcApiRestangular.all('cities'),
    Clients: WcApiRestangular.all('clients'),
    Companies: WcApiRestangular.all('companies'),
    ContactDetails: ContactDetails,
    Countries: WcApiRestangular.all('countries'),
    Currencies: WcApiRestangular.all('currencies'),
    CurrencyFilterValues: WcApiRestangular.one('currencies').all(
      'filter-values'
    ),
    Departments: WcApiRestangular.all('departments'),
    Documents: WcApiRestangular.all('documents'),
    CandidateDocuments: WcApiRestangular.all('candidatedocuments'),
    Educations: WcApiRestangular.all('educations'),
    EligibilityRegions: WcApiRestangular.all('eligibility'),
    EmailPreferenceChoices: WcApiRestangular.all('members').all(
      'email-preference-choices'
    ),
    EmailPreferences: EmailPreferences,
    EmailPreferencesList: EmailPreferencesList,
    Ethnicities: WcApiRestangular.all('ethnicities'),
    Functions: WcApiRestangular.all('functions'),
    Groups: WcApiRestangular.all('groups'),
    GroupJoinRequests: GroupJoinRequests,
    GroupMembers: GroupMembers,
    GroupMember: GroupMember,
    GroupPosts: GroupPosts,
    FileUpload: WcApiRestangular.all('profiles').one('file-upload'),
    ProfilePictureUploadPolicy: WcApiRestangular.all('profiles').one(
      'profile-picture-upload-policy'
    ),
    getRestangularObjectForUrl: getRestangularObjectForUrl,
    getWithUrl: getWithUrl,
    getWithUrlAndHandle: getWithUrlAndHandle,
    restangularize: restangularize,
    restangularizeUrl: restangularizeUrl,
    restangularizeUrlFull: restangularizeUrlFull,
    handleError: handleError,
    handleGet: handleGet,
    handleSuccess: message => successHandler(message),
    Hobbies: WcApiRestangular.all('hobbies'),
    Industries: WcApiRestangular.all('industries'),
    IndustryStandardJobTitles: WcApiRestangular.all(
      'industry-standard-job-titles'
    ),
    InfluencingApproaches: WcApiRestangular.all('influencing-approaches'),
    LeadershipStyles: WcApiRestangular.all('leadership-styles'),
    Longlist: Longlist,
    Longlists: WcApiRestangular.all('longlists'),
    LonglistAccounts: LonglistAccounts,
    LonglistContacts: LonglistContacts,
    LonglistMembers: LonglistMembers,
    LonglistMembersFull: LonglistMembersFull,
    LonglistMember: LonglistMember,
    LonglistSharing: LonglistSharing,
    LonglistDocuments: LonglistDocuments,
    LonglistCandidateDocuments: LonglistCandidateDocuments,
    retrieveMyLonglistsEndpoint: retrieveMyLonglistsEndpoint,
    Logout: WcApiRestangular.all('auth').one('logout'),
    Members: Members,
    MembersData: MembersData,
    MembersTextSearch: WcApiRestangular.all('members').all('text-search'),
    Notes: Notes,
    MemberReferrals: WcApiRestangular.all('referrals'),
    Notifications: WcApiRestangular.all('notifications'),
    NotificationSettings: WcApiRestangular.all('notifications').all('settings'),
    NonExecPositions: WcApiRestangular.all('positions').all(
      'non-exec-positions'
    ),
    NonExecPosition: NonExecPosition,
    OTTAuthentication: WcApiRestangular.all('auth').all('one-time-token'),
    objectListToIdsSerializerField: objectListToIdsSerializerField,
    OrganizationSuggestions: WcApiRestangular.all('organization-suggestions'),
    PasswordRecovery: User.all('password').all('recover'),
    Position: Position,
    Positions: WcApiRestangular.all('positions'),
    PostComments: PostComments,
    Qualifications: WcApiRestangular.all('educations').all('qualifications'),
    Questions: WcApiRestangular.all('questions'),
    Regions: WcApiRestangular.all('regions'),
    RoleObjectives: WcApiRestangular.all('role-objectives'),
    Remuneration: WcApiRestangular.all('remuneration'),
    RequestToken: WcApiRestangular.all('auth').all('request-one-time-token'),
    Resumes: Resumes,
    User: User,
    Users: WcApiRestangular.all('users'),
    Visibility: WcApiRestangular.all('visibility'),
    Covisibility: WcApiRestangular.all('visibility').all('company'),
    WorkEnvironments: WcApiRestangular.all('work-environments'),

    // helper functions
    imageUploadSuccess: imageUploadSuccess,
    imageUploadFailure: imageUploadFailure,
    listMyGroups: listMyGroups,
    listMyGroupsICanInviteTo: listMyGroupsICanInviteTo,
    listMyLonglists: listMyLonglists,
    retrieveIdentityToken: retrieveIdentityToken,
    retrieveLiveCompanies: retrieveLiveCompanies,
    retrieveMyProfile: retrieveMyProfile,
    retrieveMyCurrentPosition: retrieveMyCurrentPosition,
    retrieveMyRemuneration: retrieveMyRemuneration,
    retrieveMyVisibility: retrieveMyVisibility,
    retrieveMyAspirations: retrieveMyAspirations,
    retrieveMyCovisibility: retrieveMyCovisibility,
    retrieveProfile: retrieveProfile,

    // Expose the restangular object used
    Restangular: WcApiRestangular
  };
};

export default angular
  .module('wcCommon.services.api.apiService', [])
  .factory('API', API);
