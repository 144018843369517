import React from 'react';
import PropTypes from 'prop-types';
import { MemberProfileCandidateSummary } from '../../candidate-summary/MemberProfileCandidateSummary';
import { MemberProfileCareerHistory } from '../../career-history/MemberProfileCareerHistory';
import { MemberProfileFinancePeople } from '../../finance-people/MemberProfileFinancePeople';
import { MemberProfileNonExecPositions } from '../../non-exec-positions/MemberProfileNonExecPositions';
import { MemberProfileRegionalManagement } from '../../regional-management/MemberProfileRegionalManagement';
import { MemberProfileWorkEligibility } from '../../work-eligibility/MemberProfileWorkEligibility';
import { MemberProfileEducation } from '../../education/MemberProfileEducation';
import { MemberProfilePersonal } from '../../personal/MemberProfilePersonal';
import { ContactDetails } from '../../contact-details/ContactDetails';
import { WidthExpander } from '../../../../../../desktop/components/react/width-expander/WidthExpander';

export const MemberProfileExperienceFull = ({
  member,
  user,
  isClient = false,
  CurrencyService,
  API,
  AuthenticationService,
  CompensationsBlockedService
}) => {
  const showContacts = isClient || (user && user.email);

  return (
    <React.Fragment>
      <MemberProfileCandidateSummary summary={member.candidateSummary} />

      <MemberProfileCareerHistory
        member={member}
        isClient={isClient}
        CurrencyService={CurrencyService}
        CompensationsBlockedService={CompensationsBlockedService}
      />

      <MemberProfileFinancePeople
        member={member}
        CurrencyService={CurrencyService}
      />

      {member.nonExecPositions && member.nonExecPositions.length > 0 && (
        <MemberProfileNonExecPositions member={member} />
      )}

      {member.regionsManaged && member.regionsManaged.length > 0 && (
        <MemberProfileRegionalManagement member={member} />
      )}

      {member.eligibleRegions && member.eligibleRegions.length > 0 && (
        <MemberProfileWorkEligibility member={member} />
      )}

      {member.educations && member.educations.length > 0 && (
        <MemberProfileEducation member={member} />
      )}

      {(member.gender || member.ethnicity || member.countryOfOrigin) && (
        <MemberProfilePersonal member={member} />
      )}

      {showContacts && (
        <ContactDetails
          isClient={isClient}
          isContactable={member.isContactable}
          user={user}
          API={API}
          AuthenticationService={AuthenticationService}
        />
      )}

      <WidthExpander />
    </React.Fragment>
  );
};

MemberProfileExperienceFull.propTypes = {
  member: PropTypes.object,
  user: PropTypes.object,
  isClient: PropTypes.bool,
  CurrencyService: PropTypes.any,
  API: PropTypes.any,
  AuthenticationService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any
};
