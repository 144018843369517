class PlaceSearchService {
  constructor($q, $log, GoogleMapService) {
    this.$q = $q;
    this.$log = $log;

    this.GoogleMapService = GoogleMapService;
  }

  /* Attempt to use Google Maps API to autocomplete a query
   *
   * Returns a promise.
   *   * If Resolved, returns a list of 5 possible autocompletions of a place
   *   * On Rejection, returns the status.
   *
   * Usage:
   *   PlaceSearchService.autocomplete('bahrain')
   *     .then(successFn, failureFn)
   */
  autocomplete = query => {
    return this.GoogleMapService.get().then(gmaps => {
      let AutocompleteService = new gmaps.maps.places.AutocompleteService();
      let PlacesServiceOK = gmaps.maps.places.PlacesServiceStatus.OK;
      const request = { input: query, types: ['(cities)'] };

      return this.$q((resolve, reject) => {
        AutocompleteService.getPlacePredictions(
          request,
          (predictions, status) => {
            if (status !== PlacesServiceOK) {
              this.$log.debug('Failed to fetch places autocompletion', status);
              return reject(status);
            }

            return resolve(predictions);
          }
        );
      });
    });
  };

  getDetails = (map, placeId) => {
    if (!map || !placeId) {
      return;
    }

    return this.GoogleMapService.get().then(gmaps => {
      let service = new gmaps.maps.places.PlacesService(map);
      return this.getDetailsWithService(service, placeId);
    });
  };

  getDetailsWithService = (service, placeId) => {
    if (!placeId) {
      return;
    }

    return this.GoogleMapService.get().then(gmaps => {
      return this.$q((resolve, reject) => {
        service.getDetails(
          {
            placeId: placeId,
            fields: ['name', 'geometry', 'address_components']
          },
          (detail, status) => {
            if (status !== gmaps.maps.places.PlacesServiceStatus.OK) {
              return reject(status);
            }

            resolve(detail);
          }
        );
      });
    });
  };
}

PlaceSearchService.$inject = ['$q', '$log', 'GoogleMapService'];
PlaceSearchService.NAME = 'PlaceSearchService';

export default PlaceSearchService;
