/*
 Create .getDownloadUrl() method on a document Restangular object (called clientDocument to differentiate
 from 'DOM document')
 returns: {url: <pre-signed-url-on-private-aws-bucket>
 */

let DownloadUrlFunc = clientDocument => {
  clientDocument.addRestangularMethod('getDownloadUrl', 'get', 'download/');

  return clientDocument;
};

let DocumentDownloadRestangularMethod = RestangularProvider => {
  RestangularProvider.addElementTransformer(
    'documents',
    false,
    DownloadUrlFunc
  );
  RestangularProvider.addElementTransformer(
    'candidatedocuments',
    false,
    DownloadUrlFunc
  );
};

DocumentDownloadRestangularMethod.$inject = ['RestangularProvider'];

export default DocumentDownloadRestangularMethod;
