/* misc function to help with reducers */
const createReducer = (state, action, handlers) => {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }
  return handler(state, action);
};

export default createReducer;
