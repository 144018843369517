import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const BooleanChip = ({ active, title, falseTitle }) => {
  return (
    <div className="BooleanChip">
      <div className="BooleanChip__ChipContainer">
        <div className="BooleanChip__Chip">
          {active ? (
            <span className="BooleanChip__Check zmdi zmdi-check" />
          ) : (
            <span className="BooleanChip__Check zmdi zmdi-minus" />
          )}
        </div>
      </div>
      {active ? (
        <h3 className="BooleanChip__Title">{title}</h3>
      ) : (
        <h3 className="BooleanChip__Title">{falseTitle}</h3>
      )}
    </div>
  );
};

BooleanChip.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  falseTitle: PropTypes.string
};
