import angular from 'angular';
import { react2angular } from 'react2angular';
import { RemunerationGraph } from './RemunerationGraph';

export default angular
  .module('wc.components.RemunerationGraph', [])
  // @ts-ignore
  .component(
    'reactRemunerationGraph',
    react2angular(RemunerationGraph, ['remuneration', 'rowLayout'])
  );
