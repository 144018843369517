import angular from 'angular';
import templateUrl from './template.html';

/**
 * Display an input hint.
 *
 * The hint is the transcluded content of the element.
 *
 */

export default angular
  .module('wcCommon.components.inputs.messages.inputHint', [])
  .component('wcInputHint', {
    templateUrl: templateUrl,
    transclude: true
  });
