import angular from 'angular';
import { react2angular } from 'react2angular';
import { QAndA } from './QAndA';

export default angular
  .module('wc.components.QAndA', [])
  // @ts-ignore
  .component(
    'reactQAndA',
    react2angular(QAndA, ['question', 'answer', 'separator'])
  );
