import angular from 'angular';

import templateUrl from './template.html';
import logo from './logo.svg';
import './styles.scss';

export default angular.module('wc.components.logo', []).component('logo', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    inverse: '<'
  },
  /*@ngInject*/
  controller: function($sce) {
    this.logoSvg = $sce.trustAsHtml(logo);
  }
});
