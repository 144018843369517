import FileUploadController from '../BaseController';
import { validate } from './validation';
import templateUrl from './template.html';
import './styles.scss';

class ImageUploadController extends FileUploadController {
  /*@ngInject*/
  constructor($element, $scope, $timeout, API, S3FileUploadService) {
    super();
    this.$timeout = $timeout;
    this.API = API;
    this.S3FileUploadService = S3FileUploadService;

    this.icons = {
      READY: 'profilePicture'
    };

    // Skip validation for now, it's dodgy for a few reasons.
    //
    // 1. We were validating before crop, not after, which isn't representative of the upload.
    // 2. Validation can fail on frontend but pass on AWS
    // 3. If AWS rejects the upload, an error message is displayed anyway.
    //
    // $scope.$watch(
    //   () => this.model,
    //   () => {
    //     this.validate(this.model);
    //   }
    // );

    this.inputElement = $element.find('input')[0];
    $scope.$watch(
      () => this.previewImage,
      () => {
        if (this.previewImage) {
          this.wasPopulated = true;
          this.setUploaded();
        }
      }
    );
  }

  setUploaded() {
    super.setUploaded();
  }

  handleError() {
    this.API.handleError();
    this.errorMessage = 'An unknown error occurred during upload.';
    this.reset();
  }

  reset() {
    this.model = undefined;

    if (this.wasPopulated) {
      this.setUploaded();
      return;
    }

    this.previewImage = undefined;
    this.setReady();
  }

  validate(file) {
    if (!this.model) {
      return false;
    }

    let validationInfo = validate(file);

    if (!validationInfo.isValid) {
      this.errorMessage = validationInfo.error;
      this.model = undefined;

      if (this.previewImage) {
        this.setUploaded();
      } else {
        this.setReady();
      }
    }

    return validationInfo.isValid;
  }

  onFileUploadClick() {
    this.$timeout(() => {
      this.inputElement.click();
    });
  }

  onProcessing() {
    this.setProcessing();
  }

  onUpload(files) {
    if (!files || !this.model || files.length === 0) {
      // Nothing to process. Reset the component state and return.

      if (this.previewImage) {
        this.setUploaded();
      } else {
        this.setReady();
      }

      return;
    }

    let file = files[0];
    if (this.processImage) {
      this.processImage({ image: file })
        .then(this.saveImage.bind(this))
        .catch(this.reset.bind(this));
    } else {
      this.saveImage(file);
    }
  }

  saveImage(image) {
    this.previewImage = image;
    this.resetFeedback();
    this.setUploading();

    this.S3FileUploadService.uploadFile(image)
      .then(url => {
        this.url = url;
      })
      .then(this.setUploaded.bind(this))
      .then(this.onUploaded)
      .catch(this.handleError.bind(this));
  }
}

export default angular
  .module('wcCommon.components.inputs.imageUpload', [])
  .component('wcImageUpload', {
    bindings: {
      captions: '=',
      url: '=',
      hint: '@',
      previewImage: '@?',
      processImage: '&?',
      onUploaded: '&'
    },
    controller: ImageUploadController,
    templateUrl: templateUrl
  });
