import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

/*@ngInject*/
function CurrencyInputField($timeout) {
  return {
    restrict: 'E',
    templateUrl: templateUrl,
    scope: {
      debounce: '@?',
      disabled: '@?',
      model: '=ngModel',
      name: '@',
      placeholder: '@',
      symbol: '@',
      tooltip: '@?'
    },
    link: (scope, elem, attrs, controllers) => {
      scope.ngModelController = controllers[0];
      const formController = controllers[1];

      function updateSymbolPadding() {
        $timeout(() => {
          const symbolElement = elem[0].querySelector('.CurrencyInput__Symbol');
          const inputElement = elem[0].querySelector('.CurrencyInput__Input');

          let symbolWidth;
          if (symbolElement) {
            symbolWidth = symbolElement.clientWidth || 28;
          } else {
            symbolWidth = 28;
          }

          angular
            .element(inputElement)
            .css({ paddingLeft: `${symbolWidth + 5}px` });
        });
      }

      scope.required = attrs.hasOwnProperty('required') || undefined;

      scope.shouldDisplayError = function shouldDisplayError() {
        return (
          Object.keys(scope.ngModelController.$error).length > 0 &&
          (scope.ngModelController.$touched || formController.$submitted)
        );
      };

      scope.$watch('symbol', updateSymbolPadding);
    },
    require: ['ngModel', '^^form']
  };
}

CurrencyInputField.NAME = 'currencyInput';

export default angular
  .module('wcMobile.components.inputs.numerical.currency', [])
  .directive('currencyInput', CurrencyInputField);
