import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ClampedText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <React.Fragment>
      <div
        className="ClampedText__Container"
        onClick={() => setExpanded(state => !state)}
      >
        <div
          className={`ClampedText__Block ${
            !expanded ? 'ClampedText--clamped' : ''
          }`}
        >
          {text}
        </div>
      </div>
      <div
        className="ClampedText__MoreButton"
        onClick={() => setExpanded(state => !state)}
      >
        {expanded ? (
          <span className="zmdi zmdi-chevron-up" />
        ) : (
          <span className="zmdi zmdi-chevron-down" />
        )}
      </div>
    </React.Fragment>
  );
};

ClampedText.propTypes = {
  text: PropTypes.string
};
