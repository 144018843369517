import './styles.scss';
import FileUploadStates from '../FileUploadStates';
import templateUrl from './template.html';

class FileUploadController {
  /*@ngInject*/
  constructor($element) {
    this.$element = $element;
    this.STATES = FileUploadStates;

    this.$element[0].addEventListener('click', () => {
      this.onClick();
    });
  }
}

export default angular
  .module('wcCommon.components.inputs.fileUpload', [])
  .component('wcFileUpload', {
    bindings: {
      additionalClasses: '@?',
      caption: '@',
      errorMessage: '@',
      hint: '@',
      icons: '<',
      showButton: '<',
      previewImage: '=',
      state: '@',
      successMessage: '@',
      onClick: '&'
    },
    templateUrl: templateUrl,
    controller: FileUploadController
  });
