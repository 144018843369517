import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';

const dateDiff = (start, end = {}) => {
  let diff = moment(end).diff(moment(start), true);
  if (diff < 0) {
    diff *= -1;
  }
  let duration = moment.duration(diff);
  return duration.format();
};

export const NonExecPositionOverview = ({ position, isOwn = false }) => {
  return (
    <React.Fragment>
      {position.positionTitle && (
        <div className="WcNonExecPositionOverview__SummaryText">
          <h3 className="WcNonExecPositionOverview__JobTitle">
            {position.positionTitle}
          </h3>
          <div className="WcNonExecPositionOverview__RoleInfo">
            <span className="WcNonExecPositionOverview__CompanyName">
              {position.company}
            </span>
          </div>
          <div className="WcNonExecPositionOverview__Date">
            <span>
              {position.startDate
                ? moment(position.startDate).format('MMM YYYY')
                : 'Unknown'}
            </span>{' '}
            -{' '}
            <span>
              {position.endDate
                ? moment(position.endDate).format('MMM YYYY')
                : 'Current'}
            </span>
            {position.startDate && position.endDate && (
              <span>
                <span className="WcNonExecPositionOverview__Separator">
                  {' '}
                  |{' '}
                </span>
                {dateDiff(position.startDate, position.endDate)}
              </span>
            )}
            {position.startDate && !position.endDate && (
              <span>
                <span className="WcMinimalPositionOverview__Separator">
                  {' '}
                  |{' '}
                </span>
                {dateDiff(position.startDate)}
              </span>
            )}
          </div>
        </div>
      )}

      {!position.positionTitle && isOwn && (
        <div className="WcNonExecPositionOverview__SummaryText">
          <h3 className="WcNonExecPositionOverview__JobTitle--empty">
            Position Title
          </h3>
          <div className="WcNonExecPositionOverview__RoleInfo">
            <span className="WcNonExecPositionOverview__CompanyName">
              {position.company}
            </span>
          </div>
          <div className="WcNonExecPositionOverview__Date--empty">
            Start Date - End Date
          </div>
        </div>
      )}
      {!position.positionTitle && !isOwn && (
        <span className="WcNonExecPositionOverview__CompanyChip Chip Chip--accent">
          {position.company}
        </span>
      )}
    </React.Fragment>
  );
};

NonExecPositionOverview.propTypes = {
  position: PropTypes.object,
  isOwn: PropTypes.bool
};
