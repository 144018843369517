import { ActionTypes as CommonActionTypes } from '../actions';

const ActionTypes = {
  ...CommonActionTypes,
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_MOBILE_NUMBER: 'UPDATE_MOBILE_NUMBER'
};

const Actions = {
  updateEmail(dispatch, email) {
    dispatch({
      type: ActionTypes.UPDATE_EMAIL,
      email
    });
  },
  updateMobileNumber(dispatch, mobileNumber) {
    dispatch({
      type: ActionTypes.UPDATE_MOBILE_NUMBER,
      mobileNumber
    });
  }
};

export { Actions, ActionTypes };
