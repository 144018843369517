import { createStore, combineReducers } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';

import { tokenReducer } from './token';
import { profilesReducer } from './profiles';
import { userReducer } from './user';
import { changeContactReducer } from './changeContact';

const reducers = combineReducers({
  tokenReducer,
  profilesReducer,
  userReducer,
  changeContactReducer
});

const store = createStore(reducers, devToolsEnhancer());

export default store;
