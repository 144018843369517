import angular from 'angular';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

class CropperService {
  /*@ngInject*/
  constructor($document) {
    this.$document = $document[0];
  }

  init(imageSelector) {
    const imageElem = angular.element(
      this.$document.querySelector(imageSelector)
    )[0];
    this.cropper = new Cropper(imageElem, { aspectRatio: 1 });
  }

  getCroppedJpeg() {
    return this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
  }
}

export default angular
  .module('wcCommon.services.cropper', [])
  .service('CropperService', CropperService);
