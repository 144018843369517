/* Basic helper service to start downloading documents from JS
  Moves ugly BOM manipulation out of sight

  .download(clientDocument) => accepts restangularized Document object
 */

class DocumentDownloadService {
  /*@ngInject*/
  constructor(API) {
    this.API = API;
    this.downloadInProgress = false;
  }

  download(clientDocument, name) {
    if (!this.downloadInProgress) {
      this.downloadInProgress = true;

      let restangularizedDocument;
      // make sure object is restangularized
      if (!name) {
        restangularizedDocument = this.API.restangularize(clientDocument);
      } else {
        restangularizedDocument = this.API.getRestangularObjectForUrl(
          name,
          clientDocument.url
        );
      }

      return restangularizedDocument
        .getDownloadUrl()
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleError(error) {
    this.downloadInProgress = false;
    this.API.handleError()(error);
  }

  handleSuccess(response) {
    window.location.href = response.url;
    this.downloadInProgress = false;
    this.API.handleSuccess(`${response.name} downloaded`);
  }
}

export default angular
  .module('wcCommon.servies.documentDownload', [])
  .service('DocumentDownloadService', DocumentDownloadService);
