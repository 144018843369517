const PASSWORD_STRENGTH_OPTIONS = {
  minLength: 8,
  pathWellMode: false,
  minimumRequirements: {
    s: 0,
    u: 0,
    d: 0,
    l: 0
  }
};

export default {
  PASSWORD_STRENGTH_OPTIONS
};
