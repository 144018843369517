import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ExpandableTextarea = ({
  className = '',
  focus,
  model,
  sendOnEnter,
  placeholder,
  innerRef = null,
  onSubmit,
  onChange
}) => {
  const ref = innerRef ? innerRef : createRef();

  const [text, setText] = useState('');

  useEffect(() => {
    setText(model);
  }, [model]);

  const onChangeText = event => {
    onChange(event.target.value);
    setText(event.target.value);
    resize();
  };

  const catchEnter = event => {
    if (!sendOnEnter) {
      return;
    }
    // http://stackoverflow.com/a/41656511/1617748
    // Shouldn't use .keyCode because it is deprecated, but it is still the only thing most browsers support
    const keyCode = event.which;

    // Modified enter keys
    if (
      keyCode === 10 || // Ctrl + Enter in Chrome; won't work on Mac
      (keyCode === 13 && event.ctrlKey) || // Ctrl + Enter in FF
      (keyCode === 13 && event.shiftKey) || // Shift + Enter in Chrome
      (keyCode === 13 && event.altKey) // Alt + Enter in Chrome
    ) {
      const cursorPosition = event.target.selectionStart;

      if (cursorPosition !== event.target.selectionEnd) {
        let currentText = event.target.value;

        currentText =
          currentText.slice(0, cursorPosition) +
          '\n' +
          currentText.slice(cursorPosition);

        // setText(currentText);
        onChange(currentText);

        event.target.selectionStart = cursorPosition + 1;
        event.target.selectionEnd = cursorPosition + 1;
      }

      return;
    }

    if (keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      onSubmit();
    }
  };

  function resize() {
    if (ref.current) {
      ref.current.style.height = '0';
      ref.current.style.height = `${getTextAreaHeight()}px`;
      ref.current.style.overflowY = 'hidden';
    }
  }

  function getLineHeight() {
    const { fontSize, lineHeight } = window.getComputedStyle(ref.current);
    const lh =
      lineHeight === 'normal'
        ? parseFloat(fontSize) * 1.2
        : parseFloat(lineHeight);

    return lh;
  }

  function getTextAreaHeight() {
    const {
      borderBottomWidth,
      borderTopWidth,
      paddingBottom,
      paddingTop
    } = window.getComputedStyle(ref.current);

    const lh = getLineHeight();

    const rowHeight =
      lh +
      parseFloat(borderBottomWidth) +
      parseFloat(borderTopWidth) +
      parseFloat(paddingBottom) +
      parseFloat(paddingTop);

    const scrollHeight =
      ref.current.scrollHeight +
      parseFloat(borderBottomWidth) +
      parseFloat(borderTopWidth);

    return Math.max(rowHeight, scrollHeight);
  }

  return (
    <textarea
      ref={ref}
      value={text}
      autoFocus={focus}
      placeholder={placeholder}
      rows="1"
      autoComplete="off"
      className={`${className} WcExpandableTextarea`}
      onKeyPress={catchEnter}
      onChange={onChangeText}
    />
  );
};

ExpandableTextarea.propTypes = {
  className: PropTypes.string,
  focus: PropTypes.bool,
  sendOnEnter: PropTypes.bool,
  placeholder: PropTypes.string,
  model: PropTypes.string,
  innerRef: PropTypes.any,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};
