const NAME = 'wcAutoscrollContainer';

/*@ngInject*/
function AutoscrollContainer() {
  return {
    link: function linkAutoscrollContainer(scope, element, attributes) {
      attributes.$observe(NAME, function registerAutoscrollEventListener() {
        _scrollToBottom();
        scope.$on(attributes[NAME], function onAutoscrollEvent() {
          _scrollToBottom();
        });
      });

      function _scrollToBottom() {
        requestAnimationFrame(function() {
          element[0].scrollTop = element[0].scrollHeight;
        });
      }
    },
    restrict: 'A'
  };
}

export default angular
  .module('wc.components.autoscrollContainer', [])
  .directive(NAME, AutoscrollContainer);
