import angular from 'angular';
import { react2angular } from 'react2angular';
import { CompensationHidden } from './CompensationHidden';

export default angular
  .module('wc.components.CompensationHidden', [])
  // @ts-ignore
  .component(
    'reactCompensationHidden',
    react2angular(CompensationHidden, [], ['CompensationsBlockedService'])
  );
