import angular from 'angular';
import Clamp from './Clamp';

/*@ngInject*/

function ClampDirective($timeout) {
  function link(scope, element, attributes) {
    $timeout(() => {
      Clamp(element[0], { clamp: attributes.clamp });
    });
  }
  return {
    restrict: 'A',
    link: link
  };
}

export default angular
  .module('wcCommon.components.clamp', [])
  .directive('clamp', ClampDirective);
