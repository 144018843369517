import { ActionTypes as CommonActionTypes } from '../actions';

const ActionTypes = {
  ...CommonActionTypes,
  UPDATE_USER: 'UPDATE_USER'
};

const Actions = {
  updateUser(dispatch, user) {
    dispatch({
      type: ActionTypes.UPDATE_USER,
      user: user
    });
  }
};

export { Actions, ActionTypes };
