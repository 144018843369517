export default function cappedNumberFilter() {
  return (input, max) => {
    if (input > max) {
      return max + '+';
    } else {
      return '' + input;
    }
  };
}

cappedNumberFilter.NAME = 'cappedNumber';
