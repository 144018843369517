import angular from 'angular';

import templateUrl from './template.html';

export default angular
  .module('wcCommon.components.navbar', [])
  .component('wcNavbar', {
    templateUrl: templateUrl,
    transclude: true,
    bindings: {
      user: '<',
      navItems: '<'
    }
  });
