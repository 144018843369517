import angular from 'angular';

class GroupInvitesService {
  /*@ngInject*/
  constructor(API) {
    this.API = API;
  }

  getGroupsICanInviteMemberTo() {
    return this.API.listMyGroupsICanInviteTo().then(this.setGroups.bind(this));
  }

  setGroups(groups) {
    this.groups = groups;
  }

  inviteMemberToGroup(member, group) {
    if (!group.inviteUrl) {
      throw new Error(`Cannot invite to group ${group.id} without inviteUrl`);
    }
    return this.API.Restangular.allUrl(
      'groupInvite',
      group.inviteUrl
    ).customPOST({ member: member.id });
  }
}

export default angular
  .module('wcCommon.services.groupInvites', [])
  .service('GroupInvitesService', GroupInvitesService);
