import 'ngstorage';

import { USER_TYPES } from './api/APIConstants';
import apiModule from './api/API.module';
import messenger from '../components/messenger/Messenger.module';
import GoogleAnalyticsService from '../../common/services/analytics/Google';
import { Actions } from '../redux/actions';

const MapStateToProps = null;

const MapDispatchToProps = dispatch => ({
  clearStore: () => Actions.clearStore(dispatch)
});

class CommonAuthenticationService {
  /*@ngInject*/
  constructor(
    $localStorage,
    $sessionStorage,
    $state,
    $window,
    $ngRedux,
    API,
    MessengerService,
    TokenService,
    GoogleAnalyticsService,
    $q
  ) {
    this.$localStorage = $localStorage;
    this.$sessionStorage = $sessionStorage;
    this.$state = $state;
    this.$window = $window;
    this.$ngRedux = $ngRedux;
    this.API = API;
    this.MessengerService = MessengerService;
    this.TokenService = TokenService;
    this.GoogleAnalyticsService = GoogleAnalyticsService;
    this.$q = $q;
    this.deferredProfile = this.$q.defer();
    this.profilePromise = this.deferredProfile.promise;
    this.loggingOut = this.$q.defer();
    this.loggingOutPromise = this.loggingOut.promise;
    $ngRedux.connect(MapStateToProps, MapDispatchToProps)(this);
  }

  canLogout() {
    return (
      this.TokenService.token.sessionId &&
      this.hasUser() &&
      this.user.type === USER_TYPES.MEMBER &&
      this.isProfileComplete()
    );
  }

  cleanSessionData() {
    this.clearStore();
    this.profile = null;
    this.user = null;

    // Note that the current version of the code, these keys are never set. But for the benefit of
    // existing users, clear them in case they're left over.
    delete this.$localStorage.hasChosenPassword;
    delete this.$localStorage.isProfileComplete;
    delete this.$localStorage.userType;

    this.$sessionStorage.$reset();
  }

  getId() {
    if (this.user) {
      return this.user.id;
    }
    return null;
  }

  /**
   * .getAuthenticatedUser() must be called before checking any auth-related state (eg. .isMember())
   * to ensure that the AuthenticationService has up-to-date info.
   *
   * @returns A Promise that resolves with the logged in user.
   */
  getAuthenticatedUser() {
    if (!this.hasSession()) {
      return this.$q.resolve(null);
    }

    // If user is already set, don't hit the API again.
    if (this.hasUser()) {
      return this.$q.resolve(this.user);
    }

    return this.refresh();
  }

  hasChosenPassword() {
    return this.hasUser() && this.user.hasChosenPassword;
  }

  hasSession() {
    return !!this.TokenService.token.sessionId;
  }

  hasUser() {
    return !!this.user;
  }

  isClient() {
    return (
      this.isLoggedIn() &&
      this.hasUser() &&
      this.user.type === USER_TYPES.CLIENT
    );
  }

  isLoggedIn() {
    return this.hasUser() && this.hasSession();
  }

  isMember() {
    return (
      this.isLoggedIn() &&
      this.hasUser() &&
      this.user.type === USER_TYPES.MEMBER
    );
  }

  isMemberPublic() {
    return this.isMember() && this.profile && this.visibility
      ? this.visibility.toMembers
      : true;
  }

  isProfileComplete() {
    return this.profile && this.profile.isComplete;
  }

  isLimitedClient() {
    return this.profile && this.profile.limitedClient;
  }

  isMessagingDisabled() {
    return !this.profile || this.profile.disableMessaging;
  }

  isOwnMemberProfile(id) {
    return this.profile.id === parseInt(id);
  }

  hasAcceptedLegislation() {
    return this.profile.compensationLegislationAccepted;
  }

  shouldAcceptTerms() {
    return this.profile.shouldAcceptTerms && !this.profile.termsAccepted;
  }

  login(email, password, lifecycleHooks = {}) {
    return this.API.Authentication.post({ email: email, password: password })
      .then(this._storeTokenAndRetrieveUser.bind(this))
      .then(this._storeUserAndRetrieveProfile.bind(this))
      .then(lifecycleHooks.onRetrieveProfileSuccess)
      .then(this._storeProfileAndRetrieveVisibility.bind(this))
      .then(this._storeVisibility.bind(this))
      .then(this._commitToken.bind(this));
  }

  loginWithOneTimeToken($stateParams, lifecycleHooks = {}) {
    let params = { oneTimeToken: $stateParams.token };
    if ($stateParams.changedBy) {
      params.changedBy = $stateParams.changedBy;
      params.redirect = true;
    }
    return this.API.OTTAuthentication.post(params)
      .then(this._storeTokenAndRetrieveUser.bind(this))
      .then(lifecycleHooks.onRetrieveUserSuccess)
      .then(this._storeUserAndRetrieveProfile.bind(this))
      .then(lifecycleHooks.onRetrieveProfileSuccess)
      .then(this._storeProfileAndRetrieveVisibility.bind(this))
      .then(this._storeVisibility.bind(this))
      .then(this._commitToken.bind(this));
  }

  logout() {
    if (this.isLoggingOut) {
      return this.loggingOutPromise;
    }

    this.isLoggingOut = true;

    return this.API.Logout.get()
      .then(() => {
        this.MessengerService.logout(() => {
          this.cleanSessionData();
          this.loggingOut.resolve();
        });

        return this.loggingOutPromise;
      })
      .finally(() => {
        this.loggingOut = this.$q.defer();
        this.loggingOutPromise = this.loggingOut.promise;
        this.isLoggingOut = false;
      });
  }

  redirectToState(state, extraParams = {}) {
    this.$state.go(state, extraParams, { location: 'replace' });
  }

  redirectWithNextLocationParam(state, extraParams = {}) {
    let nextLocation = `${this.$window.location.pathname}${
      this.$window.location.search
    }`;

    if (nextLocation === '' || nextLocation === '/') {
      nextLocation = null;
    }

    this.$state.go(state, Object.assign({ next: nextLocation }, extraParams), {
      location: 'replace'
    });
  }

  /**
   * Re-fetch user + profile from API and update local storage variables.
   */
  refresh() {
    // If refresh is already in progress, return the in-progress promise.
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    const deferredUser = this.$q.defer();
    this.refreshPromise = deferredUser.promise;

    this.API.User.get()
      .then(this._storeUserAndRetrieveProfile.bind(this))
      .then(this._storeProfileAndRetrieveVisibility.bind(this))
      .then(this._storeVisibility.bind(this))
      .then(this._commitToken.bind(this))
      .finally(() => {
        deferredUser.resolve(this.user);
        this.refreshPromise = null;
      });

    return this.refreshPromise;
  }

  _storeUserAndRetrieveProfile(user) {
    this.user = user;
    return this.API.retrieveProfile(user);
  }

  _storeTokenAndRetrieveUser(tokenResponse) {
    if (!this.TokenService.token) {
      this.TokenService.setSession(tokenResponse.sessionid);
    }
    return this.API.User.get();
  }

  getProfile() {
    if (this.profilePromise) {
      return this.profilePromise;
    }

    if (this.profile) {
      return this.$q.resolve(this.profile);
    }

    this.deferredProfile = this.$q.defer();
    this.profilePromise = this.deferredProfile.promise;
    return this.profilePromise;
  }

  _storeProfileAndRetrieveVisibility(profile) {
    this.profile = profile;

    this.deferredProfile.resolve(profile);
    this.profilePromise = null;

    if (!this.isMember()) {
      return this.$q.resolve(null);
    }

    return this.API.retrieveMyVisibility(profile);
  }

  _storeVisibility(visibility) {
    this.visibility = visibility;
  }

  _commitToken() {
    // Commit the token to local storage if the user already registered to keep them logged in
    if (this.profile && this.profile.isComplete) {
      this.TokenService.commit();
    }
  }
}

export default angular
  .module('wcCommon.servies.auth', [
    'ngStorage',
    apiModule.name,
    messenger.name,
    GoogleAnalyticsService.name
  ])
  .service('CommonAuthenticationService', CommonAuthenticationService);
