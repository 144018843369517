import angular from 'angular';
import { react2angular } from 'react2angular';
import { MinimalPositionOverview } from './MinimalPositionOverview';

export default angular
  .module('wc.components.MinimalPositionOverview', [])
  // @ts-ignore
  .component(
    'reactWcMinimalPositionOverview',
    react2angular(MinimalPositionOverview, ['position'])
  );
