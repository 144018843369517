import './HighlightedImageBubble.scss';
import controller from './HighlightedImageBubble.controller';
import templateUrl from './HighlightedImageBubble.html';

export default {
  NAME: 'wcHighlightedImageBubble',
  bindings: {
    missingImageUrl: '@wcMissingImage',
    label: '@',
    src: '@wcSrc'
  },
  controller: controller,
  templateUrl: templateUrl
};
