import React from 'react';
import './styles.scss';

export const WidthExpander = () => (
  <div className="dfWidthExpander">
    This text serves only one purpose: to force the content to expand to max
    width, even if no other elements do. &apos;min-width&apos; is reserved for
    smaller devices (tablets). If you have a better solution, please contact us
    at hello@dangerfarms.com.
  </div>
);
