import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import theme from '../../../../../styles/theme';

const ErrorMessage = styled.p`
  margin: 5px 0 0 0;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.palette.error.A700};

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const InputError = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorMessage>{children}</ErrorMessage>
    </ThemeProvider>
  );
};

InputError.propTypes = {
  children: PropTypes.any
};

export default InputError;
