/*@ngInject*/
function WcApiRestangular(EnvConfig, Restangular, TokenService) {
  return Restangular.withConfig(RestangularProvider => {
    function addAuthorizationHeader(element, operation, what, url, headers) {
      if (
        url.includes('auth/token') ||
        url.includes('auth/one-time-token') ||
        url.includes('auth/request-one-time-token') ||
        url.includes('user/password/recover')
      ) {
        return {};
      }
      if (TokenService.token.sessionId) {
        headers['X-SessionID'] = `${TokenService.token.sessionId}`;
      }

      return { headers: headers };
    }

    function readSessionIdHeader(data, operation, what, url, response) {
      let sessionid = response.headers('X-SessionID');
      if (sessionid && sessionid !== TokenService.token.sessionId) {
        RestangularProvider.setDefaultHeaders({ 'X-SessionID': sessionid });
        TokenService.setSession(sessionid);
        TokenService.commit();
      }
      return response.data;
    }

    RestangularProvider.setBaseUrl(EnvConfig.apiURL);
    RestangularProvider.setRequestSuffix('/');
    RestangularProvider.setRestangularFields({ selfLink: 'url' });

    let defaultHeaders = {
      Accept: 'application/json; version=' + EnvConfig.apiVersion,
      'Content-Type': 'application/json'
    };
    if (EnvConfig.rewriteURL) {
      defaultHeaders['X-WCAPIRewrite'] = '1';
    }

    RestangularProvider.setDefaultHeaders(defaultHeaders);
    RestangularProvider.setPlainByDefault(true);

    RestangularProvider.addFullRequestInterceptor(addAuthorizationHeader);
    RestangularProvider.addResponseInterceptor(readSessionIdHeader);
  });
}

export default angular
  .module('wcCommon.services.api.wcApiRestangular', [])
  .factory('WcApiRestangular', WcApiRestangular);
