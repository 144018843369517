class CompensationsBlockedService {
  /*@ngInject*/
  constructor(API, $q, $rootScope) {
    this.API = API;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.isReadyDeferred = this.$q.defer();
    this.isReady = this.isReadyDeferred.promise;
    this.getValue();
  }

  getValue() {
    if (this.inProgress === true) {
      return;
    }
    this.inProgress = true;
    this.API.retrieveMyProfile()
      .then(this.setValue.bind(this))
      .catch(this.handleError.bind(this));
  }

  setValue(profile) {
    this.profile = profile;
    this.blocked = this._compensationsBlocked(profile);
    this.inProgress = false;
    this.isReadyDeferred.resolve();
  }

  _hasCompanyAndCompensationsBlocked(profile) {
    // This is an edge case scenario,
    // as although every real Client should have a company,
    // it is possible to create one without or remove the company
    return profile && profile.company
      ? profile.company.compensationsBlocked
      : false;
  }

  _compensationsBlocked(profile) {
    return profile && profile.compensationsBlocked
      ? profile.compensationsBlocked
      : this._hasCompanyAndCompensationsBlocked(profile);
  }

  isBlocked() {
    return this.blocked;
  }

  setBlocked() {
    // A client isn't allowed to set it to true
    // If it is allowed, refactor to changeBlocked and pass in the value
    return this.API.restangularize(this.profile)
      .patch({ compensationsBlocked: true })
      .then(() => {
        this.blocked = true;
        this.$rootScope.$broadcast('compensationBlockedChanged');
      })
      .catch(this.API.handleError.bind(this));
  }

  handleError(error) {
    this.API.handleError()(error);
  }
}

export default angular
  .module('wcCommon.services.compensationsBlockedService', [])
  .service('CompensationsBlockedService', CompensationsBlockedService);
