import { ActionTypes } from './actions';
import createReducer from '../reducer';

const initialState = {
  user: {
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    mobileNumberSharing: null
  }
};

const clearState = state => {
  return Object.assign({}, state, initialState);
};

const updateUser = (state, action) => {
  return Object.assign({}, state, {
    user: {
      ...state.user,
      ...action.user
    }
  });
};

const handlers = {
  [ActionTypes.CLEAR_STORE]: clearState,
  [ActionTypes.UPDATE_USER]: updateUser
};

const userReducer = (state = initialState, action) => {
  return createReducer(state, action, handlers);
};

export default userReducer;
export { initialState };
