import { ActionTypes } from './actions';
import createReducer from '../reducer';

// TODO(mihail): define somewhere the entire shape of the state
const initialState = {
  myProfile: {
    profilePicture: null,
    opexAmount: null,
    opexCurrency: null,
    capexAmount: null,
    capexCurrency: null,
    profitAndLossAmount: null,
    profitAndLossCurrency: null,
    revenueTargetAmount: null,
    revenueTargetCurrency: null,
    fullTimeEmployees: null,
    extendedTeamSize: null,
    directReports: null,
    regionsManaged: null,
    educations: [],
    ethnicity: null,
    ethnicityConsent: null,
    gender: null,
    countryOfOrigin: null,
    address: null,
    // TODO(mihail): move this to API
    patch: null
  },
  countryOptions: []
};

const clearState = state => {
  return Object.assign({}, state, initialState);
};

const updateMyProfile = (state, action) => {
  return Object.assign({}, state, {
    myProfile: {
      ...state.myProfile,
      ...action.myProfile
    }
  });
};

const updateEducations = (state, action) => {
  return Object.assign({}, state, {
    myProfile: {
      ...state.myProfile,
      educations: action.educations
    }
  });
};

const updateCountryOptions = (state, action) => {
  return Object.assign({}, state, {
    countryOptions: action.countryOptions
  });
};

const updateAddress = (state, action) => {
  return Object.assign({}, state, {
    myProfile: {
      ...state.myProfile,
      address: action.address
    }
  });
};

const handlers = {
  [ActionTypes.CLEAR_STORE]: clearState,
  [ActionTypes.UPDATE_MY_PROFILE]: updateMyProfile,
  [ActionTypes.UPDATE_EDUCATIONS]: updateEducations,
  [ActionTypes.UPDATE_COUNTRY_OPTIONS]: updateCountryOptions,
  [ActionTypes.UPDATE_ADDRESS]: updateAddress
};

const profilesReducer = (state = initialState, action) => {
  return createReducer(state, action, handlers);
};

export default profilesReducer;
export { initialState };
