import React from 'react';
import PropTypes from 'prop-types';

class InputHint extends React.Component {
  render() {
    return <div className="FormInputHint">{this.props.children}</div>;
  }
}

InputHint.propTypes = {
  // TODO(mihail): we shouldn't be specifying children normally
  children: PropTypes.any
};

export default InputHint;
