import angular from 'angular';

import analytics from './analytics/Google';
import api from './api/API.module';
import commonAuthentication from './CommonAuthentication';
import compensationsBlockedService from './CompensationsBlockedService';
import cropper from './Cropper';
import currency from './Currency';
import documentDownload from './DocumentDownload';
import groupInvites from './GroupInvites.service';
import preferredCurrency from './PreferredCurrency';
import redirect from './Redirect';
import s3 from './S3FileUpload';
import token from './Token';

export default angular.module('wcCommon.services', [
  analytics.name,
  api.name,
  commonAuthentication.name,
  compensationsBlockedService.name,
  cropper.name,
  currency.name,
  documentDownload.name,
  groupInvites.name,
  preferredCurrency.name,
  redirect.name,
  s3.name,
  token.name
]);
