import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { filterLargeNumbers } from '../../../../services/LargeNumbers';

export const StatsWidget = ({ stats }) => {
  const [column1, setColumn1] = useState([]);
  const [column2, setColumn2] = useState([]);
  const [column3, setColumn3] = useState([]);

  useEffect(() => {
    const statsWithValue = stats.filter(stat => stat.value);
    const firstColumnLastItemIndex = Math.ceil(statsWithValue.length / 3);
    const secondColumnLastItemIndex = Math.ceil(
      (statsWithValue.length * 2) / 3
    );
    const column1Tmp = statsWithValue.slice(0, firstColumnLastItemIndex);
    const column2Tmp = statsWithValue.slice(
      firstColumnLastItemIndex,
      secondColumnLastItemIndex
    );
    const column3Tmp = statsWithValue.slice(secondColumnLastItemIndex);
    setColumn1(column1Tmp);
    setColumn2(column2Tmp);
    setColumn3(column3Tmp);
  }, [stats]);

  return (
    <div className="StatsWidget__Container">
      <div className="StatsWidget__Column">
        {column1.map((stat, index) => (
          <div className="StatsWidget__Row" key={index}>
            {stat.currencySymbol ? (
              <h3 className="StatsWidget__Stat">
                {filterLargeNumbers(stat.value, 2, stat.currencySymbol)}
              </h3>
            ) : (
              <h3 className="StatsWidget__Stat">
                {filterLargeNumbers(stat.value)}
              </h3>
            )}
            <h3 className="StatsWidget__Header">{stat.label}</h3>
          </div>
        ))}
      </div>
      <div className="StatsWidget__Column">
        {column2.map((stat, index) => (
          <div className="StatsWidget__Row" key={index}>
            {stat.currencySymbol ? (
              <h3 className="StatsWidget__Stat">
                {filterLargeNumbers(stat.value, 2, stat.currencySymbol)}
              </h3>
            ) : (
              <h3 className="StatsWidget__Stat">
                {filterLargeNumbers(stat.value)}
              </h3>
            )}
            <h3 className="StatsWidget__Header">{stat.label}</h3>
          </div>
        ))}
      </div>
      <div className="StatsWidget__Column">
        {column3.map((stat, index) => (
          <div className="StatsWidget__Row" key={index}>
            {stat.currencySymbol ? (
              <h3 className="StatsWidget__Stat">
                {filterLargeNumbers(stat.value, 2, stat.currencySymbol)}
              </h3>
            ) : (
              <h3 className="StatsWidget__Stat">
                {filterLargeNumbers(stat.value)}
              </h3>
            )}
            <h3 className="StatsWidget__Header">{stat.label}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

StatsWidget.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.object)
};
