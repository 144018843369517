import { parse, format } from 'libphonenumber-js';

export default function internationalPhoneNumberFilter() {
  return input => {
    try {
      return format(parse(input), 'International');
    } catch (e) {
      return input;
    }
  };
}

internationalPhoneNumberFilter.NAME = 'internationalPhoneNumber';
