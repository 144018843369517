let useFilter = $filter => {
  return (value, filterName, args) => {
    return $filter(filterName)(value, ...args);
  };
};

useFilter.$inject = ['$filter'];
useFilter.NAME = 'useFilter';

export default useFilter;
