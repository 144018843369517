import angular from 'angular';

/*
 * Usage: {{ userObj | wcCompanySummary:jobTitleKey:companyKey }}
 *
 * where jobTitleKey and companyKey are optional arguments to tell the filter where in the object to find the job title
 * and company strings.
 *
 * Default value for jobTitleKey is jobTitle
 * Default value for companyKey is currentCompany
 */
function companySummary() {
  return function summariseCompany(input, jobTitleKey, companyKey) {
    if (!jobTitleKey) {
      jobTitleKey = 'currentInternalJobTitle';
    }

    if (!companyKey) {
      companyKey = 'currentCompany';
    }

    return `${input[jobTitleKey]}, ${input[companyKey]}`;
  };
}

export default angular
  .module('wc.filters.companySummary', [])
  .filter('wcCompanySummary', companySummary);
