/*@ngInject*/
function WcResumeRestangular(EnvConfig, WcApiRestangular) {
  return WcApiRestangular.withConfig(function(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHeaders({
      Accept: 'application/json; version=' + EnvConfig.apiVersion
    });
  });
}

export default angular
  .module('wcCommon.services.api.resumeRestangular', [])
  .factory('WcResumeRestangular', WcResumeRestangular);
