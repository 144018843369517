import angular from 'angular';
import { react2angular } from 'react2angular';
import { ContactDetails } from './ContactDetails';

export default angular
  .module('wc.components.ContactDetails', [])
  // @ts-ignore
  .component(
    'reactContactDetails',
    react2angular(
      ContactDetails,
      ['user', 'isClient', 'isContactable'],
      ['API', 'AuthenticationService']
    )
  );
