let RequestResponseInterception = ($rootScope, Restangular) => {
  Restangular.addRequestInterceptor(element => {
    $rootScope.$broadcast('addActiveRequest');
    return element;
  });
  Restangular.addResponseInterceptor(data => {
    $rootScope.$broadcast('resolveActiveRequest');
    return data;
  });
  Restangular.addErrorInterceptor(data => {
    $rootScope.$broadcast('resolveActiveRequest');
    return data;
  });
};

RequestResponseInterception.$inject = ['$rootScope', 'Restangular'];
export default RequestResponseInterception;
