import angular from 'angular';
import './CookieBar.scss';
import templateUrl from './CookieBar.html';

const CookieBar = {
  controller: function() {},
  NAME: 'wcCookieBar',
  templateUrl: templateUrl
};

export default angular
  .module('wcCommon.components.cookieBar', [])
  .component(CookieBar.NAME, CookieBar);
