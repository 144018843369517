export const POSITION_TYPES = {
  FULL_TIME: 'F',
  INTERIM: 'I'
};

export function sortPositions(positions) {
  positions.sort((a, b) => {
    if (a.current && !b.current) {
      return -1;
    }
    if (b.current && !a.current) {
      return 1;
    }
    if (a.startDate === b.startDate) {
      return 0;
    }
    return a.startDate < b.startDate ? 1 : -1;
  });
  return positions;
}

export function sortNonExecPositions(positions) {
  positions.sort((a, b) => {
    // old non-exec positions were migrated to new format, but do not have any dates
    // move this positions to the top and sort by name

    let isMigrated = position => {
      return position.startDate === null && position.endDate === null;
    };

    if (isMigrated(a) && isMigrated(b)) {
      return a.company.localeCompare(b.company);
    }

    if ((a.endDate === null && b.endDate !== null) || isMigrated(a)) {
      return -1;
    }
    if ((b.endDate === null && a.endDate !== null) || isMigrated(b)) {
      return 1;
    }
    if (a.startDate === b.startDate) {
      return a.company.localeCompare(b.company);
    }
    return a.startDate < b.startDate ? 1 : -1;
  });
  return positions;
}
