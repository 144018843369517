import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';
class PasswordStrengthMeterController {
  $onChanges() {
    if (this.strength >= 75) {
      this.strengthText = 'strong';
    } else if (this.strength >= 50) {
      this.strengthText = 'medium';
    } else {
      this.strengthText = 'weak';
    }
  }
}

export default angular
  .module('wcCommon.components.passwordstrengthmeter', [])
  .component('wcPasswordStrengthMeter', {
    bindings: {
      strength: '<',
      showText: '<'
    },
    controller: PasswordStrengthMeterController,
    templateUrl: templateUrl
  });
