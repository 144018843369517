import angular from 'angular';
import { react2angular } from 'react2angular';
import { BooleanChip } from './BooleanChip';

export default angular
  .module('wc.components.BooleanChip', [])
  // @ts-ignore
  .component(
    'reactBooleanChip',
    react2angular(BooleanChip, ['active', 'title', 'falseTitle'])
  );
