const ActionTypes = {
  CLEAR_STORE: 'CLEAR_STORE'
};

const Actions = {
  clearStore(dispatch) {
    dispatch({
      type: ActionTypes.CLEAR_STORE
    });
  }
};

export { Actions, ActionTypes };
