import angular from 'angular';
import { MIN_DECIMAL } from '../NumericalLimits';

function minDecimal() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.min = function(modelValue) {
        return !modelValue || modelValue >= MIN_DECIMAL;
      };
    }
  };
}

minDecimal.NAME = 'dfMinDecimal';

export default angular
  .module('dfComponents.validators.minDecimal', [])
  .directive(minDecimal.NAME, minDecimal);
