import angular from 'angular';

import './Textarea.scss';

import controller from './Textarea.controller';
import templateUrl from './Textarea.html';

const textarea = {
  NAME: 'wcTextarea',
  bindings: {
    ngModel: '=',
    maxLength: '<maxlength',
    name: '@'
  },
  controller: controller,
  templateUrl: templateUrl
};

const dependencies = [];

export default angular
  .module('wcComponents.textarea', dependencies)
  .component(textarea.NAME, textarea);
