import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileExperienceFull } from './MemberProfileExperienceFull';

export default angular
  .module('wc.components.MemberProfileExperienceFull', [])
  // @ts-ignore
  .component(
    'reactMemberProfileExperienceFull',
    react2angular(
      MemberProfileExperienceFull,
      ['member', 'user', 'isClient'],
      [
        'CurrencyService',
        'API',
        'AuthenticationService',
        'CompensationsBlockedService'
      ]
    )
  );
