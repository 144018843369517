import angular from 'angular';

import validPhoneNumber from './validPhoneNumber.directive';
import maxDecimal from './maxdecimal';
import maxNumber from './maxnumber';
import minDecimal from './mindecimal';
import minNumber from './minnumber';

export default angular.module('wcCommon.validators', [
  maxDecimal.name,
  maxNumber.name,
  minDecimal.name,
  minNumber.name,
  validPhoneNumber.name
]);
