import React from 'react';
import PropTypes from 'prop-types';
import { EducationOverview } from './../../../education-overview/EducationOverview';

export const MemberProfileEducation = ({ member }) => {
  return (
    <article className="Separator">
      <h2 className="WcMemberProfileContent__Subheading">Education</h2>
      <div>
        <EducationOverview educations={member.educations} />
      </div>
    </article>
  );
};

MemberProfileEducation.propTypes = {
  member: PropTypes.object
};
