import angular from 'angular';
import { react2angular } from 'react2angular';
import { ImageBubble } from './ImageBubble';

export default angular
  .module('wc.components.ImageBubble', [])
  // @ts-ignore
  .component(
    'reactImageBubble',
    react2angular(ImageBubble, ['src', 'caption'])
  );
