import './ConversationDetail.scss';
import templateUrl from './ConversationDetail.html';

import moment from 'moment';

class ConversationDetailController {
  /*@ngInject*/
  constructor(ChatService, MessengerService) {
    this.ChatService = ChatService;
    this.MessengerService = MessengerService;

    this.isUserMessage = this.isUserMessage.bind(this);
  }

  $onInit() {
    if (!this.conversation || !this.ChatService.userId) {
      return;
    }

    this.setCurrentUserSid();
  }

  async setCurrentUserSid() {
    const member = await this.conversation.getMemberByIdentity(
      this.ChatService.userId
    );
    this.currentUserSid = member.sid;
  }

  isUserMessage(message) {
    return this.currentUserSid === message.memberSid;
  }

  /**
   * Used to apply styling when messages should be merged with previous message.
   *
   * @param previousMessage
   * @param message
   * @returns {boolean} False if `message` is the first message in the conversation, or the
   *                    previous message was sent by another user, or the previous message
   *                    sent by the same user within one day;
   *                    True otherwise.
   */
  shouldMergeTop(previousMessage, message) {
    if (!previousMessage) {
      return false;
    }
    if (previousMessage.memberSid !== message.memberSid) {
      return false;
    }
    return (
      moment(message.timestamp).diff(previousMessage.timestamp, 'days') < 1
    );
  }

  /**
   * Used to apply styling when messages should be merged with the next message.
   *
   * @param message
   * @param nextMessage
   * @returns {boolean} False if `message` is the last message in the conversation, or the
   *                    next message was sent by another user, or the next message sent by
   *                    the same user within one day;
   *                    True otherwise.
   */
  shouldMergeBottom(message, nextMessage) {
    if (!nextMessage) {
      return false;
    }
    if (message.memberSid !== nextMessage.memberSid) {
      return false;
    }
    return moment(nextMessage.timestamp).diff(message.timestamp, 'days') < 1;
  }
}

export default {
  NAME: 'wcConversationDetail',
  bindings: {
    conversation: '<',
    messages: '<'
  },
  controller: ConversationDetailController,
  templateUrl: templateUrl
};
