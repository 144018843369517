import angular from 'angular';
import { react2angular } from 'react2angular';
import { CircularImage } from './CircularImage';

export default angular
  .module('wcCommon.components.reactCircularImage', [])
  // @ts-ignore
  .component(
    'reactWcCircularImage',
    react2angular(CircularImage, ['alt', 'src', 'missingImageUrl', 'className'])
  );
