const MAX_DOCUMENT_SIZE_BYTES = 2000000; // 2MB
const ALLOWED_DOCUMENT_TYPES = ['application/pdf'];

export function validate(file) {
  /* it will display false error in IE if there isn't installed PDF reader application,
  because file.type = "" in this cases */
  if (ALLOWED_DOCUMENT_TYPES.indexOf(file.type) === -1) {
    return {
      isValid: false,
      error: `The file you selected is not a valid PDF file.`
    };
  }

  if (file.size > MAX_DOCUMENT_SIZE_BYTES) {
    return {
      isValid: false,
      error: `The file you selected exceeds the maximum upload size of 2MB.`
    };
  }

  return { isValid: true };
}
