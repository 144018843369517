import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const CompensationHidden = ({ CompensationsBlockedService }) => {
  const [compensationsBlocked, setCompensationsBlocked] = useState(false);

  useEffect(() => {
    CompensationsBlockedService.isReady.then(() => {
      setCompensationsBlocked(CompensationsBlockedService.isBlocked());
    });
  }, [CompensationsBlockedService]);

  return (
    <div>
      {compensationsBlocked
        ? 'You have chosen to hide compensation data. To display compensation again,please visit your settings.'
        : 'This user has chosen not to share their compensation information with you.'}
    </div>
  );
};

CompensationHidden.propTypes = {
  CompensationsBlockedService: PropTypes.any
};
