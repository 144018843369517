import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatsWidget } from './../../../stats-widget/StatsWidget';

export const MemberProfileFinancePeople = ({ member, CurrencyService }) => {
  const [managementStats, setManagementStats] = useState([]);
  useEffect(() => {
    if (hasAnyValue()) {
      fillManagementStats();
    }
  }, []);

  const hasCurrencyValue = val => {
    return val && parseFloat(val) > 0;
  };

  const hasAnyValue = () => {
    return !!(
      hasCurrencyValue(member.capexAmount) ||
      hasCurrencyValue(member.opexAmount) ||
      member.fullTimeEmployees ||
      member.directReports ||
      member.extendedTeamSize
    );
  };

  const fillManagementStats = () => {
    const managementStatsTmp = [
      {
        label: 'Capital Expenditure',
        value: member.capexAmount !== null ? parseFloat(member.capexAmount) : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(member.capexCurrency)
      },
      {
        label: 'Operational Expenditure',
        value: member.opexAmount !== null ? parseFloat(member.opexAmount) : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(member.opexCurrency)
      },
      {
        label: 'Profit and Loss',
        value:
          member.profitAndLossAmount !== null
            ? parseFloat(member.profitAndLossAmount)
            : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(
          member.profitAndLossCurrency
        )
      },
      {
        label: 'Revenue Target',
        value:
          member.revenueTargetAmount !== null
            ? parseFloat(member.revenueTargetAmount)
            : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(
          member.revenueTargetCurrency
        )
      },
      { label: 'Number of FTEs', value: member.fullTimeEmployees },
      { label: 'Number of Direct Reports', value: member.directReports },
      { label: 'Extended Team', value: member.extendedTeamSize }
    ];
    setManagementStats(managementStatsTmp);
  };

  return hasAnyValue() ? (
    <article className="Separator">
      <h2 className="WcMemberProfileContent__Subheading">
        Finance &amp; People Management
      </h2>
      <div className="WcMemberProfileContent__InfoText">
        Details of the largest budgets and team sizes managed over the past 10
        years
      </div>
      <div>
        {managementStats && managementStats.length > 0 && (
          <StatsWidget stats={managementStats} />
        )}
      </div>
    </article>
  ) : null;
};

MemberProfileFinancePeople.propTypes = {
  member: PropTypes.object,
  CurrencyService: PropTypes.any
};
