/*@ngInject*/
/**
 * GoogleAnalyticsConfigProvider
 *
 */
export default function GoogleAnalyticsConfigProvider(
  AnalyticsProvider,
  EnvConfigProvider
) {
  if (EnvConfigProvider.$get().googleAnalyticsAccountID !== undefined) {
    AnalyticsProvider.ignoreFirstPageLoad(true);
    AnalyticsProvider.setPageEvent('$stateChangeSuccess');
    AnalyticsProvider.setRemoveRegExp(/[a-zA-Z0-9]{100}/);

    AnalyticsProvider.setAccount({
      tracker: EnvConfigProvider.$get().googleAnalyticsAccountID,
      set: {
        anonymizeIp: true
      }
    });
  }
}

GoogleAnalyticsConfigProvider.NAME = 'GoogleAnalyticsConfig';
