import { parse, isValidNumber } from 'libphonenumber-js';

function PhoneNumber() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.phoneNumber = function(modelValue, viewValue) {
        try {
          let parsedNumber = parse(viewValue);
          return isValidNumber(parsedNumber);
        } catch (e) {
          return false;
        }
      };

      ctrl.$validators.e164 = function(modelValue, viewValue) {
        return !viewValue || !viewValue.length || viewValue[0] === '+';
      };
    }
  };
}

export default angular
  .module('wcCommon.validators.validPhoneNumber', [])
  .directive('wcValidPhoneNumber', PhoneNumber);
