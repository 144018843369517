import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import './CookieBar.scss';

export const CookieBar = () => {
  return (
    <div className="CookieBar">
      This website uses cookies. Read<a target="_blank" href="https://www.wintercircle.co/cookies.html" rel="noreferrer">our policy</a>.
    </div>
  )
}

export default angular
  .module('wcCommon.components.reactCookieBar', [])
  // @ts-ignore
  .component('reactWcCookieBar', react2angular(CookieBar, []));
