/*
 * Allows easy access to the list of all custom Restangular objects used in the project.
 */
class CustomRestangulars {
  /*@ngInject*/
  constructor(
    Restangular,
    WcApiRestangular,
    WcFullResponseRestangular,
    WcResumeRestangular
  ) {
    this.Restangular = Restangular; // Include the base object in case that's used anywhere.
    this.WcApiRestangular = WcApiRestangular;
    this.WcFullResponseRestangular = WcFullResponseRestangular;
    this.WcResumeRestangular = WcResumeRestangular;
  }

  all() {
    return [
      this.Restangular,
      this.WcApiRestangular,
      this.WcFullResponseRestangular,
      this.WcResumeRestangular
    ];
  }
}

export default angular
  .module('wcCommon.services.api.customRestangulars', [])
  .service('WcCustomRestangulars', CustomRestangulars);
