import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import InverseButton from './../../../../../../desktop/components/react/buttons/InverseButton/InverseButton';
import { ThemeProvider } from 'styled-components';
import theme from '../../../../../styles/theme';

export const ContactDetails = ({
  user,
  isClient = false,
  isContactable = false,
  API,
  AuthenticationService
}) => {
  const [userState, setUserState] = useState(user);
  const [getDetailsButtonDisabled, setGetDetailsButtonDisabled] = useState(
    false
  );
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    AuthenticationService.getProfile().then(profile => {
      setShowContact(profile && !profile.hideContact);
    });
  }, [AuthenticationService]);

  const getContactDetails = () => {
    setGetDetailsButtonDisabled(true);
    API.ContactDetails(userState.id)
      .then(updateUserWithContactDetails.bind(this))
      .catch(API.handleError.bind(this));
  };

  const updateUserWithContactDetails = contactDetails => {
    const userTmp = Object.assign(userState, contactDetails);
    setUserState(userTmp);
    setGetDetailsButtonDisabled(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <article className="WcContactDetails">
        {!isClient ? (
          <div className="layout-gt-xs-row">
            {userState.email && (
              <div className="flex">
                <h2 className="WcMemberProfileContent__Subheading">
                  Email Address
                </h2>
                <p>{userState.email}</p>
              </div>
            )}
            {userState.contactSharing && (
              <div className="flex">
                <h2 className="WcMemberProfileContent__Subheading">
                  Contact Number
                </h2>
                <p>{userState.mobileNumber}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="layout-column">
            {isContactable && showContact && !userState.email && (
              <div className="layout-column">
                <div className="WcMemberProfileContent__Contact layout-row">
                  <InverseButton
                    disabled={getDetailsButtonDisabled}
                    onClick={getContactDetails}
                  >
                    <span className="zmdi zmdi-eye" />
                    Show Contact Details
                  </InverseButton>
                </div>
              </div>
            )}
            {userState.email && (
              <div className="layout-gt-xs-row">
                <div className="flex">
                  <h2 className="WcMemberProfileContent__Subheading">
                    Email Address
                  </h2>
                  <p>{userState.email}</p>
                </div>
                {userState.mobileNumber && (
                  <div className="flex">
                    <h2 className="WcMemberProfileContent__Subheading">
                      Contact Number
                    </h2>
                    <p>{userState.mobileNumber}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </article>
    </ThemeProvider>
  );
};

ContactDetails.propTypes = {
  user: PropTypes.object,
  isClient: PropTypes.bool,
  isContactable: PropTypes.bool,
  API: PropTypes.any,
  AuthenticationService: PropTypes.any
};
