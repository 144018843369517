import angular from 'angular';
import { react2angular } from 'react2angular';
import InverseButton from './InverseButton';

export default angular
  .module('wc.components.InverseButton', [])
  // @ts-ignore
  .component(
    'reactInverseButton',
    react2angular(InverseButton, [
      'ariaLabel',
      'className',
      'onClick',
      'children',
      'label',
      'icon',
      'withBorder',
      'withMargin'
    ])
  );
