import angular from 'angular';

/*@ngInject*/
function NumberFormat($filter) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: (scope, element, attrs, ngModelController) => {
      function formatNumber(value) {
        if (!value && value !== 0) {
          return '';
        }

        return $filter('number')(value) || value;
      }

      function stripCommas(value) {
        if (value) {
          return value.replace(/,/g, '');
        } else {
          return '';
        }
      }

      element.on('blur', () => {
        ngModelController.$render = function renderInputWithCommas() {
          element[0].value = formatNumber(ngModelController.$viewValue);
        };

        ngModelController.$render();
        ngModelController.$setTouched();
      });

      element.on('focus', () => {
        ngModelController.$render = function renderInputWithoutCommas() {
          element[0].value = stripCommas(ngModelController.$viewValue);
        };

        ngModelController.$render();
      });

      // Maintain model as a pure integer
      ngModelController.$parsers.push(stripCommas);
      ngModelController.$formatters.push(formatNumber);
    }
  };
}

export default angular
  .module('wcCommon.components.inputs.numerical.format', [])
  .directive('numberFormat', NumberFormat);
