import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const EducationOverview = ({ educations }) => {
  return (
    <React.Fragment>
      {educations.map(education => (
        <div className="EducationOverview" key={education.id}>
          <div className="EducationOverview__TextColumn">
            <h3 className="EducationOverview__SchoolText">
              {education.school}
            </h3>
            <h4 className="EducationOverview__QualificationText">
              {education.qualificationName}, {education.fieldOfStudy}
              {education.year && <span>, {education.year}</span>}
            </h4>
          </div>

          <div className="EducationOverview__LogoColumn">
            {education.universityLogo && (
              <img
                src={education.universityLogo}
                alt="logo"
                className="EducationOverview__Logo"
              />
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

EducationOverview.propTypes = {
  educations: PropTypes.arrayOf(PropTypes.object)
};
