import angular from 'angular';

import templateUrl from './template.html';

import './styles.scss';

import alertCircle from './assets/alertCircle.svg';
import chatBubblesIcon from './assets/chatBubbles.svg';
import chatIcon from './assets/chat.svg';
import checkIcon from './assets/check.svg';
import cross from './assets/cross.svg';
import cvIcon from './assets/cv.svg';
import leftArrowIcon from './assets/leftArrow.svg';
import padlockIcon from './assets/padlock.svg';
import profileIcon from './assets/profile.svg';
import profilePictureIcon from './assets/profilePicture.svg';
import searchIcon from './assets/search.svg';
import sendIcon from './assets/send.svg';
import settingsIcon from './assets/settings.svg';

class Controller {
  /*@ngInject*/
  constructor($sce, $attrs) {
    this.$sce = $sce;
    this.attributeToSrcMap = {
      alertCircle: alertCircle,
      padlock: padlockIcon,
      profile: profileIcon,
      profilePicture: profilePictureIcon,
      settings: settingsIcon,
      chat: chatIcon,
      chatBubbles: chatBubblesIcon,
      check: checkIcon,
      cross: cross,
      cv: cvIcon,
      leftArrow: leftArrowIcon,
      search: searchIcon,
      send: sendIcon
    };

    this.accent = 'accent' in $attrs;
  }

  $onInit() {
    let svg = this.attributeToSrcMap[this.icon];
    this.iconSvg = this.$sce.trustAsHtml(svg);
  }
}

export default angular
  .module('wcCommon.components.icon', [])
  .component('wcIcon', {
    bindings: {
      icon: '@'
    },
    controller: Controller,
    templateUrl: templateUrl
  });
