import angular from 'angular';

/*
 * Usage: {{ userObj | wcLocationSummary:cityKey:countryKey:stateKey }}
 *
 * where cityKey, countryKey and stateKey are optional arguments to tell the filter where in the object to find the
 * relevant strings.
 *
 * Default value for cityKey is locationCity
 * Default value for countryKey is locationCountry
 * Default value for stateKey is locationState
 */
function locationSummary() {
  return function summariseLocation(input, cityKey, countryKey, stateKey) {
    if (!cityKey) {
      cityKey = 'addressCity';
    }

    if (!countryKey) {
      countryKey = 'addressCountry';
    }

    if (!stateKey) {
      stateKey = 'addressState';
    }

    let string = `${input[cityKey]}`;

    if (input[stateKey] && input[stateKey].length > 0) {
      string += `, ${input[stateKey]}`;
    }

    if (input[countryKey] && input[countryKey].length > 0) {
      string += `, ${input[countryKey]}`;
    }

    return string;
  };
}

export default angular
  .module('wc.filters.locationSummary', [])
  .filter('wcLocationSummary', locationSummary);
