import 'angular-tooltips/dist/angular-tooltips.min';
import 'angular-tooltips/dist/angular-tooltips.min.css';
import './TooltipIcon.scss';
import tooltipIcon from './TooltipIcon.directive';

let tooltipModule = angular
  .module('wcCommon.components.tooltip', ['720kb.tooltips'])
  .directive(tooltipIcon.NAME, tooltipIcon);

export default tooltipModule;
