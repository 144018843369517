import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileFinancePeople } from './MemberProfileFinancePeople';

export default angular
  .module('wc.components.MemberProfileFinancePeople', [])
  // @ts-ignore
  .component(
    'reactMemberProfileFinancePeople',
    react2angular(MemberProfileFinancePeople, ['member'], ['CurrencyService'])
  );
