import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';

/*@ngInject*/
let Resume = (API, EnvConfig, $http) => {
  function saveFile(response, fileName = 'Resume') {
    let contentDispositionHeader = response.headers('content-disposition');
    if (contentDispositionHeader) {
      let disposition = contentDisposition.parse(contentDispositionHeader);
      fileName = disposition.parameters['filename'];
    }
    const blob = new Blob([response.data], {
      type: response.headers('content-type')
    });
    saveAs(blob, fileName);
  }

  function triggerDownloadJS(url, fileName) {
    API.restangularizeUrlFull(url)
      .withHttpConfig({ responseType: 'arraybuffer' })
      .get()
      .then(response => {
        saveFile(response, fileName);
      });
  }

  function download(url, memberName) {
    let fileName = `${memberName} Resume`;
    if (url.indexOf('/download') !== -1) {
      API.restangularizeUrl(url)
        .get()
        .then(response => {
          if (response.url.indexOf(EnvConfig.apiURL) === -1) {
            $http
              .get(response.url, { responseType: 'arraybuffer' })
              .then(response => {
                saveFile(response, fileName);
              });
          } else {
            triggerDownloadJS(response.url, fileName);
          }
        });
    } else {
      triggerDownloadJS(url, fileName);
    }
  }

  return {
    download,
    triggerDownloadJS
  };
};

Resume.NAME = 'Resume';

export default /*@ngInject*/ Resume;
