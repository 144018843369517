import FileUploadStates from './FileUploadStates';

class FileUploadController {
  constructor() {
    this.STATES = FileUploadStates;
  }

  $onInit() {
    this.setReady();
  }

  setReady() {
    this.state = this.STATES.READY;
    this.caption = this.captions.READY;
  }

  setProcessing() {
    this.state = this.STATES.PROCESSING;
    this.caption = this.captions.PROCESSING;
  }

  setUploading() {
    this.state = this.STATES.UPLOADING;
    this.caption = this.captions.UPLOADING;
  }

  setUploaded() {
    this.state = this.STATES.UPLOADED;
    this.caption = this.captions.UPLOADED;
  }

  resetFeedback() {
    this.errorMessage = undefined;
    this.successMessage = undefined;
  }
}

FileUploadController.controllerAs = 'fileUploadCtrl';
export default FileUploadController;
