import angular from 'angular';
import CircularImage from '../circularimage/CircularImage.module';

import './OverviewCard.scss';
import templateUrl from './OverviewCard.html';

const OverviewCard = {
  NAME: 'wcOverviewCard',
  bindings: {
    profilePicture: '<wcProfilePicture',
    missingImageUrl: '@wcMissingImageUrl'
  },
  templateUrl: templateUrl,
  transclude: {
    wcOverviewCardButtonsLeft: '?wcOverviewCardButtonsLeft',
    wcOverviewCardButtonsRight: '?wcOverviewCardButtonsRight',
    wcOverviewCardContent: 'wcOverviewCardContent'
  }
};

const dependencies = [CircularImage.name];

export default angular
  .module('wcCommon.components.overviewcard', dependencies)
  .component(OverviewCard.NAME, OverviewCard);
