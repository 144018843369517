import angular from 'angular';
import { MAX_DECIMAL } from '../NumericalLimits';

function maxDecimal() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.max = function(modelValue) {
        return !modelValue || modelValue <= MAX_DECIMAL;
      };
    }
  };
}

maxDecimal.NAME = 'dfMaxDecimal';

export default angular
  .module('dfComponents.validators.maxDecimal', [])
  .directive(maxDecimal.NAME, maxDecimal);
