import React from 'react';
import PropTypes from 'prop-types';
import { MemberProfileCareerHistory } from '../../career-history/MemberProfileCareerHistory';
import { MemberProfileRegionalManagement } from '../../regional-management/MemberProfileRegionalManagement';
import { MemberProfileNonExecPositions } from '../../non-exec-positions/MemberProfileNonExecPositions';
import { MemberProfileEducation } from '../../education/MemberProfileEducation';
import { WidthExpander } from '../../../../../../desktop/components/react/width-expander/WidthExpander';

export const MemberProfileExperienceLimited = ({
  member,
  CurrencyService,
  CompensationsBlockedService
}) => {
  return (
    <React.Fragment>
      <MemberProfileCareerHistory
        member={member}
        CurrencyService={CurrencyService}
        CompensationsBlockedService={CompensationsBlockedService}
      />

      {member.nonExecPositions && member.nonExecPositions.length > 0 && (
        <MemberProfileNonExecPositions member={member} />
      )}

      {member.regionsManaged && member.regionsManaged.length > 0 && (
        <MemberProfileRegionalManagement member={member} />
      )}

      {member.educations && member.educations.length > 0 && (
        <MemberProfileEducation member={member} />
      )}

      <WidthExpander />
    </React.Fragment>
  );
};

MemberProfileExperienceLimited.propTypes = {
  member: PropTypes.object,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any
};
