import angular from 'angular';
import { MIN_INTEGER } from '../NumericalLimits';

function minNumber() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.min = function(modelValue) {
        return modelValue >= MIN_INTEGER;
      };
    }
  };
}

minNumber.NAME = 'dfMinNumber';

export default angular
  .module('dfComponents.validators.minNumber', [])
  .directive(minNumber.NAME, minNumber);
