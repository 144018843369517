import angular from 'angular';
import 'ngstorage';

import { Actions as TokenActions } from '../redux/token/actions';

const MapStateToProps = state => ({
  token: state.tokenReducer
});

const MapDispatchToProps = dispatch => ({
  setSession: sessionId => TokenActions.setSession(dispatch, sessionId),
  clearSession: () => TokenActions.clearSession(dispatch)
});

class TokenService {
  /*@ngInject*/
  constructor($localStorage, $ngRedux) {
    this.$localStorage = $localStorage;
    $ngRedux.connect(MapStateToProps, MapDispatchToProps)(this);
  }
  commit() {
    this.$localStorage.sessionid = this.token.sessionId;
  }
}

export default angular
  .module('wc.services.token', ['ngStorage'])
  .service('TokenService', TokenService);
